<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Dunlop Landingpage</h2>
                <h3>Frontend programming for Dunlop</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/dunlopProject/dunlopHeader.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über11">&#10035; PROJECT</span><br>
                    for Dunlop GmbH
                </div>
                <div class="what">
                    <span class="Über11">&#10035; SEMESTER</span><br>
                    WS 2023 Internship <br> @DieProduktMacher
                </div>
                <div class="what">
                    <span class="Über11">&#10035; DURATION</span><br>
                    1 Monate
                </div>
                <div class="what">
                    <span class="Über11">&#10035; TOOLS</span><br>
                    CSS <br> HTML <br> TypeScript
                </div>
                <div class="what">
                    <span class="Über11">&#10035; TEAM</span><br>
                    Manuel <br> Lippmann
                </div>
              </div>  
            </div>
        <br>
        <hr class="hr11">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline11"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
              <p class="left">
                  At DPM, I also got the chance to support the development team so that I could immerse myself further in the world of programming. I was able to help program two landing pages for Dunlop. Initially, I only created individual modules of a landing page in constant exchange with a mentor who took over the programming tasks in this project. Later, I was even allowed to program the second landing page completely on my own. Afterwards, we went through all the modules together and looked at what I could improve. I learned a lot about structured and collaborative work in programming and how to use Git. I learned how to work with Liquid templates and TypeScript. I improved immensely, especially in HTML, CSS and frontend programming and gained valuable insights into development processes and the approach to customer projects.
                    customer projects.</p>
                <br>
                <a href="https://www.dunlopboots.com/fit-guide.html" target="_blank"><h1 class="ani11">Visit the website</h1></a>
                <br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline11"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Atoms</span></h5><br><br><br><br>
                <p class="left">
                  According to the Atomic Design Principle, we have broken down the website into its smallest atoms to create a modular system.
                  An atom represents the smallest component of the web design and cannot be broken down into further elements. An atom can be a heading, input line or button. </p>
                <br>
                <img class="controller" src="../assets/dunlopProject/atoms.png" alt="">
                <br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline11"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Modules</span></h5><br><br><br><br>
                <p class="left">
                  Modules allow you to structure and organize your TypeScript code across multiple files. Modules allow the TypeScript code to be split into individual files. However, each TypeScript file or module also has its own area in which classes, functions and variables are visible. This feature of modules is particularly helpful in larger projects, as it allows the same function name to be used in different modules, for example, without causing problems.
                </p>
                <br>
                <img class="controller" src="../assets/dunlopProject/modules.png" alt="">
                <br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline11"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Main Page</span></h5><br><br><br><br>
                <p class="left">
                  The main landing page is then created from these combined modules. It can be variable, dynamic and modular. A reusable structure is also encouraged.</p>
                <br>
                <img class="controller" src="../assets/dunlopProject/mainPage.png" alt="">
                <br><br><br><br>
            </div>
        </div>
        <br>
        <router-link to="/deepflow"><h1 class="ani11">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
  .headline11{
    color: #282C34;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani11 {
    color: #282C34;
}
nobr {
    color: #282C34;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold11{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #282C34;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #282C34;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline11{
  color: #282C34;
}
.hr11{
    border-width: 2.5px;
    border-color: #282C34;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #282C34; 
  border: 5px solid #282C34;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über11{
    color: #282C34;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 80%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #282C34;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani11{
  font-size: 50px;
  color: #282C34;
}
.ani11:hover{
  color: rgb(0, 0, 0);
}
.ani11::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #282C34;
}
.ani11:hover:after{
  color: #282C34;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #282C34;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani11{
    font-size: 25px;
  }
  .ani11:hover:after{
    color: #282C34;
    width: 30%;
  }
  .Über11{
    word-wrap: none;
  }
  .headline11{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold11{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr11 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr11 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline11 {
    font-size: 24px;
  }
}
</style>