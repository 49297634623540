<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Fatigue tracker using machine learning</h2>
                <h3>A device using biometric data that can detect drowsiness at the wheel to prevent the risk of micro-sleep and is intended to be easy to retrofit in private cars.</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/fatigueProject/deviceFT.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über10">&#10035; COURSE</span><br>
                    Machine <br> Learning
                </div>
                <div class="what">
                    <span class="Über10">&#10035; SEMESTER</span><br>
                    SS 2022 IoT4
                </div>
                <div class="what">
                    <span class="Über10">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über10">&#10035; TOOLS</span><br>
                    Mediapipe <br> p5.js <br> Techable Machine
                </div>
                <div class="what">
                    <span class="Über10">&#10035; TEAM</span><br>
                    Vivienne Vollmar <br> Noah Mantel
                </div>
              </div>  
            </div>
        <br>
        <hr class="hr10">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline10"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <div class="left">
                  <h4 class="einleitung">Microsleep is a major problem on the road. 26% of drivers in the 1448 fatal accidents caused in 2020, fell asleep at the wheel. To address this problem, we programmed a voice interface that accompanies the user during the ride, encouraging him to take a break and stimulating brain activity.  </h4>
                </div>
                <p class="left">
                  Nowadays, almost everyone feels stressed. Long workdays and a tightly timed daily routine contribute to the fact that large parts of the population are generally chronically overtired. If the stressful situations continue for years, many people become accustomed to the grueling daily routine and perceive the symptoms that can lead to overpowering microsleep as normal components of their everyday lives. This microsleep sometimes lasts not just a second or two, but often the car steers around driverless for up to 15 seconds before a crash occurs.
                  The most common and recognizable symptom of fatigue is frequent blinking due to irritated eyes, slamming of eyelids and yawning.</p>
                <br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline10"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Concept</span></h5><br><br><br><br>
                <p class="left">
                  The device will use biometric data to detect how tired a person is while driving. The main features for detecting drowsiness are eyelid width and blink rate. Conventional sleep trackers use breathing rate and body activity to calculate sleep cycles. This method is not possible here because drivers move little and cannot be tracked using these features when awake. 
                </p>
                <p class="left">
                  We want to measure the blink frequency and the width of the eyelids by using a video camera, which takes pictures of the driver at a certain frame rate. On these images, a mesh on the face will be detected to thus measure the width of the eyelids. 
                </p>
                <p class="left">
                  Users will also be assisted by a voice interface. The audio interface speaks to one when fatigue is detected, tells one to take a break, and asks different questions designed to stimulate brain activity. 
                </p>
                <p class="left">
                  The device is also equipped with a microphone and records an audio signal once the question is asked. The driver's recorded answer is then analyzed again using machine learning to determine if the correct answer was given. 
                  The questions that were answered incorrectly are repeated until they are answered correctly. As a result, you are "not left alone." 
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline10"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Device</span></h5><br><br><br><br>
                <img src="../assets/fatigueProject/DeviceSkizzeFT.png" class="plant1" alt="">
                <img src="../assets/fatigueProject/cockpitFT.png" class="plant" alt="">
                <br><br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline10"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Technical implementation</span></h5><br><br><br><br>
                <p class="left">
                  The software uses the Mediapipe solution "FaceMesh" to track how often the person blinks in front of the camera and how long they keep their eyes closed. As soon as the person blinks more than 25 times per minute or keeps his eyes closed for more than 2 seconds, a notification is sent via MQTT to the p5.js application, which then starts the quiz, reads out the questions through the "p5.sound.js" library and rates the person's answers according to correctness using a "Teachable Machine Audio-Project" model.
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline10"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Prototype</span></h5><br><br><br>
                <div style="padding:65.89% 0 0 0;position:relative;">
                  <iframe src="https://player.vimeo.com/video/755515056?h=3ca3815dd7" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="screenrecord"></iframe>
                </div>
                <br><br><br>
            </div>
        </div>
        <br>
        <router-link to="/dunlop"><h1 class="ani10">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'fatiguetrackerView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.headline10{
    color: #4D52D5;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani10 {
    color: #4D52D5;
}
nobr {
    color: #4D52D5;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #4D52D5;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold10{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #4D52D5;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #4D52D5;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline10{
  color: #4D52D5;
}
.hr10{
    border-width: 2.5px;
    border-color: #4D52D5;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #4D52D5; 
  border: 5px solid #4D52D5;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.plant1{
  padding-top: 10px;
  width: 50%;
  padding-left: 0px;
}
.Über10{
    color: #4D52D5;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 70%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #4D52D5;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani10{
  font-size: 50px;
  color: #4D52D5;
}
.ani10:hover{
  color: rgb(0, 0, 0);
}
.ani10::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #4D52D5;
}
.ani10:hover:after{
  color: #4D52D5;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani10{
    font-size: 25px;
  }
  .ani10:hover:after{
    color: #4D52D5;
    width: 30%;
  }
  .Über10{
    word-wrap: none;
  }
  .headline10{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold10{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr10 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr10 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline10 {
    font-size: 24px;
  }
}
</style>