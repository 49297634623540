<template>
    <div class="container">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Remote Gardening</h2>
                <h3 class="h3">A smart plant bed that can be controlled from anywhere and networked with friends to ensure that your plants are always healthy and optimally watered. </h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/remoteProject/ne.png" alt="">
            </div>
        </div><br><br>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über5">&#10035; COURSE</span><br>
                    Technische <br> Grundlagen 2 
                </div>
                <div class="what">
                    <span class="Über5">&#10035; SEMESTER</span><br>
                    SS 2021 IoT2
                </div>
                <div class="what">
                    <span class="Über5">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über5">&#10035; TOOLS</span><br>
                    Arduino <br> Vue.js <br> Fritzing <br> MongoDB 
                </div>
                <div class="what">
                    <span class="Über5">&#10035; TEAM</span><br>
                    Joschua Rothenbacher <br> 
                    Jannes Blobel
                </div>
              </div>  
        </div>
        <br>
        <hr class="hr5">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline5"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Components</span></h5><br><br><br>
                <p class="left">
                  There is a visualization of all components and our technical parts we used for the ESP 8266.
                </p><br>
                <img src="../assets/remoteProject/setup.png" class="plant" alt="">
                <br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline5"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Setup</span></h5><br><br><br>
                <p class="left">
                  'Remote Gardening' consists of a basic framework with a threaded rod and a steel bar that can be assembled with 3D printed parts. The threaded rod is driven by a stepper motor so that the object attached to it can move. At the beginning of the threaded rod is a limit switch that allows a reference run and the ride can thus be calibrated. A carriage that is also 3D printed, runs on this straight track. An RFID reader is attached to its front
                and a hose can be fed through an opening at the bottom of the sled. The hose can be used to water the plants. Now a flower pot with an RFID tag attached to it, must be placed against the base structure. Whenever the RFID reader detects such an RFID tag, the stepper motor pauses and the threaded rod and carriage stop. Then, through the hose, with the help of a peristaltic pump, water is pumped into the flower pot. A 3D printed box attached to one end of the threaded rod contains all the arduino components. 
                </p><br>
                <img src="../assets/remoteProject/scribble.png" class="plant" alt="">
                <br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline5"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Backend</span></h5><br><br><br>
                <p class="left">
                  The backend is connected to the MongoDB database and stores all added plants. Changed values are also transferred so that they can always be displayed in the frontend. Each plant is marked with its own RFID-UID and can be found there.
                  Twice a day, the stepper motor is driven and the sled is moved along the track. The RFID reader reads all tags and checks whether the RFID-UID already exists in the database and if so, how often it needs to be watered. Thereupon, the peristaltic pump is started and waters the plant as long as defined in the database.
                  The repository can be found at the following link on GitHub: <br>
                  <a href="https://github.com/hfg-joschua-r/RemoteGardeningTG2/tree/Stable">https://github.com/hfg-joschua-r/RemoteGardeningTG2/tree/Stable</a>
                </p>
                <img src="../assets/remoteProject/backend.png" class="plant" alt="">
                <br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline5"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Frontend</span></h5><br><br><br>
                <p class="left">
                  The frontend contains a home view that shows all added plants in the real arrangement. You open the overview page of a plant by clicking on any plant on the home page. There you can see all specific information about the plant itself and information about the watering process, such as the last watered time.
                </p>
                <img src="../assets/remoteProject/frontend.png" class="plant" alt="">
                <br>
                <p class="left">
                  The user can only add a plant if he has the RFID-Reader record the RFID tag on the new plant. Then it is recognized that the RFID-UID doesn't exist in the database. The user receives an email from remotegardening@gmail.de and can get to the frontend with an additional link. The new plant can be created and defined there.
                </p><br>
                <br><br>
                <div class="lll" style="padding:65.89% 0 0 0;position:relative;">
                  <iframe src="https://player.vimeo.com/video/712350054?h=eec5241bcb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Sequenz5_1" align="left">
                  </iframe>
                </div>
            </div>
            <br><br><br><br>
            <router-link to="/gardenmoisture"><h1 class="ani5">Next project</h1></router-link>
            <br><br><br>
        </div>
    </div>
</template>
    
<script>
import NavigationBar from '@/components/NavigationBar.vue'
    export default {
        name: 'gardeningView',
        components: {
             NavigationBar
        },
        mounted(){
            window.scrollTo(0, 0)
        }
    }
</script>
    
<style>
.headline5{
    color: #a9a9a9 !important;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani5 {
    color: #a9a9a9;
}
nobr {
    color: #a9a9a9;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
.h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #a9a9a9;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #a9a9a9;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #a9a9a9;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline5{
  color: #a9a9a9 !important;
}
.hr5{
    border-width: 2.5px;
    border-color: #a9a9a9 !important;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  align-items: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #a9a9a9 !important; 
  border: 5px solid #a9a9a9 !important;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über5{
    color: #a9a9a9 !important;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 60%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #a9a9a9 !important;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani5{
  text-align: center;
  font-size: 50px;
  color: #a9a9a9 !important;
}
.ani5:hover{
  color: rgb(0, 0, 0);
}
.ani5::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #a9a9a9 !important;
}
.ani5:hover:after{
  color: #a9a9a9 !important;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani5{
    font-size: 25px;
  }
  .ani5:hover:after{
    color: #a9a9a9;
    width: 30%;
  }
  .Über5{
    word-wrap: none;
  }
  .headline5{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  hr {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  hr {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline5 {
    font-size: 24px;
  }
}
</style>