<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Garden Moisture</h2>
                <h3>A plant monitoring for your own garden</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/gardenProject/gardenHeader.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über">&#10035; COURSE</span><br>
                    Softwareentwurf und <br> Anwendung verteilter <br> Systeme
                </div>
                <div class="what">
                    <span class="Über">&#10035; SEMESTER</span><br>
                    WS 2021/22 IoT3
                </div>
                <div class="what">
                    <span class="Über">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über">&#10035; TOOLS</span><br>
                    HTML <br> CSS <br> JavaScript <br> MongoDB
                </div>
                <div class="what">
                    <span class="Über">&#10035; TEAM</span><br>
                    Fabienne Vatter
                </div>
              </div>  
        </div>
        <img src="../assets/see.png" class="width" alt="">
        <br>
        <hr>
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <div class="left">
                  <h4 class="einleitung">The “Garden Moisture” project is all about plant monitoring in your own garden.</h4>
                  <p class="left">The use case is that a user who wants to plant a plant in the garden can buy the tool. The product contains a monitoring tool. It has a humidity and nutrient sensor. For the future, a temperature and light sensor can also be added. The technology is located at the top of the tool; an ESP8266 and a CR2032 button battery are currently hidden under the housing.
                    The whole thing would look like this:</p>
                </div>
                <div style="padding:71.22% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/988443472?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Garden Moisture Frontend &amp; Backend"></iframe></div>
                <br><br>
                <p class="left">
                  The tool also comes with instructions and an enclosed note containing the Mac address of the ESP8266. The user can then use this to create their own module in the system. The user is taken to the website where he can register and log in. On the start page, he can then type in the enclosed Mac address via “Add Module” and can thus give his tool (ESP) a name and save it in the system. Back on the main page, the module will be displayed after it has been loaded. The user then has the option of viewing more detailed information or deleting the module that has just been created in order to give it a different name.
              </p>
            </div>
            <br><br>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project structure</span></h5><br><br><br><br>
                <p class="left">
                  Before I come to the distributed system itself, I would like to briefly discuss the structure. As I have several sensors (two in the current prototyping), higher-level modules have been created. This means that the two sensors are in one module. This is the tool that is then sent to the user. Another module can only be added if the user buys a second toolbox and creates another module on the website with the corresponding Mac address. In my case, there is only one module on the website, as I only have one ESP. Now to the structure of the code:
                  The distributed system “Garden Moisture” is divided into two services, the data service and the user service. The front end and the MongoDB form the interfaces. All sensor data from the modules is stored in the MongoDB. The humidity sensor is val1 and the nutrient sensor is val2. Users are registered and logged into the user service, i.e. users are stored in the database and a token is assigned. Passwords are also hashed during registration and login. This service also validates the token for each endpoint query and checks whether user authentication has taken place. If not, the user is automatically redirected to the login. A session timeout is also used here to ensure that the session is ended after a certain time (in this case 15 minutes). The user is also taken back to the login when they click. This ensures that nobody can gain unauthorized access to the data. 
                  All sensor data and module data can be accessed in the Dataservice using the database. A new module can be added to the website in the API endpoint “addModule”. The corresponding entries can be found in the database using the Mac address supplied, the user can then give them a name and these entries are then assigned to this module and also to the user (username supplied by login). The API endpoint “getModule” can be used to output the data and the module. Currently, only the last five entries are displayed here, but in future it will be possible to display much more data in a dashboard or even call up historical data. The API endpoint “deleteModule” deletes the module being searched for. In this case, the name of the module and the user are overwritten with the value “null”, i.e. the module is deleted from your own list. This ESP is then either available to another person or the user can give it a new name. 
                  In each of these endpoints, a query for the current token is always carried out first in the user service. If the token is invalid, the database query and everything else that should be executed in this endpoint is canceled and the user is redirected to the login page. 
                  In the front end there are 6 views and one component, the “navigation”. There is a login page and a registration page, which are linked to each other. This allows the user to choose whether they are already logged in or have to create an account first. After registering, the user is taken to the “/modules” page. There he can create his module with the Mac address and his own name. Once this has been done on the “/addmodule” page, the module must be loaded on the main page (“/modules”). The “Details” button then takes you to the “/sensordata” page where you can see more detailed information, such as the time of creation and the last values of the two sensors. Or you can delete the module using the “Delete Module” button.  
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Sensor module</span></h5><br><br><br><br>
                <p class="left">
                  The module has two sensors, the humidity sensor and the nutrient sensor. These collect data and then send it from the ESP to the backend via mqtt. The data is used there as described above. Under “The project” you can see how the sensors are attached to the tool. The user no longer has to install or mount the sensors. All they have to do is plug the device into the ground and register on the website.
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">User authentication login and logout</span></h5><br><br><br><br>
                <p class="left">
                  The user is authenticated via the login and a token is created. The system always checks whether a token is available; if not, this means that the user has not logged in and cannot access the data.</p>                <!-- <img src="../assets/plant4.png" class="plant" alt=""> -->
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Sessions and tokens</span></h5><br><br><br><br>
                <p class="left">
                  When the user logs in, a session is created that saves the token, the date of creation and modification and the user ID. With every endpoint query in the data service, the API endpoint “/validateToken” in the user service is then used to check whether the session is still active and a token has been sent. After 15 minutes in which the website is not used, the session is ended.                </p>                <!-- <img src="../assets/plant6.png" class="plant" alt=""> -->
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Hashing</span></h5><br><br><br><br>
                <p class="left">
                  To ensure the user's security, their passwords are hashed, making it very difficult to find out what the encrypted password is afterwards. The passwords are also only saved in the hash value in the database and compared with this value when logging in to check whether it is the correct password.                </p>               
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Display of stored sensor values</span></h5><br><br><br><br>
                <p class="left">
                  The sensor values are displayed to the user in a table. They can see which module they belong to and at what time they were recorded. I also tried to insert a table with the help of apexcharts, but due to a lack of time I was unfortunately unable to implement this completely and had to delete it just before it was finished.                </p>                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Communication technology</span></h5><br><br><br><br>
                <p class="left">
                  I currently use the ESP8266 and Mqtt for data transmission. I have a very small garden and also a repeater in the garden, so I also have wifi in the garden. This starting point is perfect for prototyping. However, if you want to make the project marketable, you need to give it more thought. Many people can already use the tool themselves. The prerequisite is a small garden in which the wifi from the house is still available or a reapeater/router located in the garden. 
                  In practice, however, you should also think about alternatives. What happens if the garden is too big or there is no WiFi nearby? How can I then get the data generated by my ESP into the database and onto the website? On the one hand, there is LoRaWan. The main problem I see here is that coverage in Germany is not yet very good. You can't assume that there are gateways available everywhere. This variant is something for real tinkerers and technology professionals. But these are not my target group.     There is also the option of allowing the tool to communicate via the mobile network with 2G or 3G. This would require an ESP32 with a GSM header and a SIM card slot. However, these are somewhat more expensive than an ESP8266.
                  In the end, if you look at the given possibilities and the user group, an ESP8266 that communicates via mqtt (wifi) is the simplest and cheapest. Unfortunately, you have to exclude those users who don't have wifi in their garden.     </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Energy supply</span></h5><br><br><br><br>
                <p class="left">
                  The “Garden Moisture” system can be used in the garden, but of course also in the house, conservatory or greenhouse. Of course, it is difficult to connect the small tool directly to the power supply in the garden and I also want to make the tool as self-sufficient as possible for reasons of mobility. The first step was to find out about rechargeable batteries and batteries. With modern LiFePo4 batteries, an ESP32 can be optimally powered and even recharged. This also ensures the sustainability of a battery. In addition, this battery works without any problems in WiFi mode and therefore seems very practical for my application.
                  In the garden, however, a small solar panel is of course also very sustainable and practical.
                  However, a solar panel that could supply my ESP with a voltage of 5V is about the size of an A4 sheet of paper. On further research, however, I came across relatively small solar panels which, at 9cm and a price of €5, look like a very good final solution. 
                  With a Deepsleep implementation, the system can shut down completely between sending the data, which only happens once a day, so much less power is consumed.         In the end, both options, a solar panel or a battery, sound like a good solution and I would want to test both options before deciding on one.                </p>
                <br><br>
            </div>
        </div>
        <br>
        <router-link to="/malu"><h1 class="ani">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.headline2{
    color: #71ac4e;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani1 {
    color: #71ac4e;
}
nobr {
    color: #71ac4e;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #71ac4e;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #71ac4e;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #71ac4e;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline{
  color: #71ac4e;
}
hr{
    border-width: 2.5px;
    border-color: #71ac4e;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #71ac4e; 
  border: 5px solid #71ac4e;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über{
    color: #71ac4e;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 60%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #71ac4e;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani{
  font-size: 50px;
  color: #71ac4e;
}
.ani:hover{
  color: rgb(0, 0, 0);
}
.ani::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #71ac4e;
}
.ani:hover:after{
  color: #71ac4e;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani{
    font-size: 25px;
  }
  .ani:hover:after{
    color: #71ac4e;
    width: 30%;
  }
  .Über{
    word-wrap: none;
  }
  .headline{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  hr {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  hr {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline {
    font-size: 24px;
  }
}
</style>