<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Prison Sensory Room</h2>
                <h3>Rehabilitation through
                  meaningful relationships</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/prisonProject/hands.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über9">&#10035; COURSE</span><br>
                    Design Studio 2: Context
                </div>
                <div class="what">
                    <span class="Über9">&#10035; SEMESTER</span><br>
                    SS 2023 IoT6
                </div>
                <div class="what">
                    <span class="Über9">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über9">&#10035; TOOLS</span><br>
                    Figma <br> Miro
                </div>
                <div class="what">
                    <span class="Über9">&#10035; TEAM</span><br>
                    Kristo Klementi <br> Nikolai Maklakov
                </div>
              </div>  
        </div>
        <br>
        <hr class="hr9">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <p class="left">
                  Prison as an institution serves many purposes in the modern world. The first is the prisons role as a place for punishment for the ones who do not follow the laws and regulations of any given state. Another reason for prison as an institution is the rehabilitation factor. The prison system aims to rehabilitate the inmates so that they would not commit new crimes in the prison and also after they are released from the prison. Unfortunately, the percentage of inmates who find their way back in to prison is over 50% in most states in European union. From this we can see that prisons are a complex system with many different variables, functions and components. This paper aims to look closer at the Estonian prison system. In particular the rehabilitation process in Estonian prisons will be studied and analyzed. When it comes to rehabilitation and reducing recidivism we can see that we still have a long way to go. Any work that can make this situation improve is needed not only for the inmates who are in the prison system but also the society as a whole. The biggest opportunity to change prisons to the better are in the field of easing the re-entry to society by providing social program in- and outside of prisons and creating a safe environment to support personal growth and legal behaviour.</p>
                <br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Prison research</span></h5><br><br><br><br>
                <p class="left">
                  The case study took place in Tallinn prison.
                  This institution is located in Rae municipality and holds around 1200 inmates. Both men and women are stationed in this location. The number of men is around 1100 and the number of women inmates is around 100. Additionally around 100 inmates are allocated in the open prison. 
                  The Tallinn prison is operated by around 600 members of staff which includes around 350 prison officers. The prison offers various possibilities for work and education as well as hobbies and other activities for the inmates.  
                  <br><br><img src="../assets/prisonProject/prison1.png" class="plant" alt=""><br><br>
                  For additional research, we visited the Finnish prison in Hämeenlinna. Hämeenlinna prison, opened in 1972, holds the distinction of being the largest closed unit for female prisoners in Finland. One particular facility participated in the Smart Prison Project, which began in 2018. Through the integration of cell terminals with a smart system, the project aimed to introduce digital services for enhanced inmate communication and access to educational resources. This initiative is a positive example of a forward-looking approach to improving the prison environment and facilitating the resocialization of prisoners. </p>
                  <img src="../assets/prisonProject/prison2.png" class="plant" alt=""><br><br>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Interviews</span></h5><br><br><br><br>
                <p class="left">
                  Our research was mostly based on 3 visits to Tallinn prison. During these visits we were given in depth tours of the prison and its various buildings. In addition to this we experienced the prison lunch and visited the kitchen of this prison.
                  Probably the most important part of the research that we did were the interviews with staff and inmates. We had the opportunity to interview various members of the staff including guards, social workers, contact persons, activity advisors and church minister. The interviews that we conducted with the inmates included male and female inmates as well as an inmate who was under custody and was not yet convicted.
                  These interviews were noted down together with the general observations and then further analyzed after the visits. This meant that after each visit we had time to reflect on the previous visit and interviews and fine tune our points and questions for the next visits and interviews. Every visit gave us more and more insights into the prison system and world that is usually kept away from the public eye.
                  After all the visits were concluded and all the interviews finished we started to use service design practices and methodology to analyze our findings. These findings were summarized and analyzed even further to find out key insights and themes that this paper is aimed at.                </p>                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Main insights</span></h5><br><br><br><br>
                <p class="left">
                1. In the current prison system, there is a lack of equal communication between guards and inmates that adds up with the unwelcoming physical environment by giving them not the trustful and respectful treatment they need to feel like a human that is understood.
                2. In the current prison system, there is a lack of tailored treatment for the individual needs of inmates regarding the act and nature of their crime to give them the proper opportunities to reestablish a legal behaviour, support them in rehabilitation and give them responsibility to enable change in their self-development journey.
                3. The architecture of the prison building plays a surprising role in the experience and communication between the inmates and staff.
                4. The inmates have very limited ways to be alone (without supervision) physically but also their personal information is often unavailable as well.
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Problem statement</span></h5><br><br><br><br>
                <p class="left">
                  The issues decided to be studied are about factors caused by separating the inmate and society. Of course the necessity and benefits of it are indisputable, but the negative influence of separation must be considered and aimed to be efficiently dealt with.
                  The mentioned negative influence is destruction of the social part of a human being the inmate. Missing the normal kind of interactions and conversations for a long enough time might cause irreversible changes to behavior. In short view, the person might lose the ability to trust anybody or even worse - to communicate.
                  Besides normal communication, there is also a topic of environment. Grey floors, unnaturally coloured section walls and lack of natural light influence social side of a human as much as lack of communication. Those effects are a topic of studies outside the prison system, similar conditions causing effects of illness are called cabin fewer [5]. Those effects can be summarized as constant tiredness and impatience after staying in same closed environment for long time.
                  In Tallinn prison’s case the most impressing environmental regulations are: lack of greenery, any plants or nature; absence of any privacy for male inmates; colorful inhumane distinction of sections and extra-performance aimed approach to logistics through underground tunnels.
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Solution Mindmap</span></h5><br><br><br><br>
                <img src="../assets/prisonProject/solutionMindmap.png" class="plant" alt=""><br><br><br>
                <p class="left">
                  Our project aims to reduce recidivism by promoting successful rehabilitation through fostering meaningful relationships and interactions among inmates, contact persons and other stakeholders both inside and outside of prison, and supporting reentry into society.We want to achieve that by reintroducing functions of contact person, implementing version 2.0. Idea behind it would be to focus on communication aspects of work with inmates, forming a meaningful relationship through out the whole journey. Goal of communication is to move from risk assessment driven discussions into a more free-world type of formation. 
                  Environmental change supporting the social work improvements is designed as sensory room. A space available for inmates private visits, where they could take their time to be alone, meet visitors, make video calls and more. 
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">The concept</span></h5><br><br><br><br>
                <p class="left">
                  Our concept can be divided into two main components: contact person v2.0 and sensory room. By focusing on these components — we strive to create a comprehensive and holistic approach to rehabilitation. Our goal is to empower inmates with the skills, resources, and relationships necessary to successfully reintegrate into society, lead fulfilling lives, and break the cycle of recidivism. Together, these initiatives lay the foundation for a brighter future, both for the individuals we serve and for the communities to which they will return.
                  The idea of contact person v2.0 holds in it few important factors: reevaluation of communication value, introduction of after-release support and normal life orientation. In the current state, contact person is extremely influential on inmates, v2.0 strives to embrace that and put into use for rehabilitation.
                  Sensory room is aimed to provide the helping environment for rehabilitation oriented support. There the inmate could feel the missing normal life properties of prison walls, find privacy and room for normal life inspiration.
                  The prison system as a whole must face a change in terms of perception of it’s task, in a way that for proper rehabilitation - meaningful relationships of contact persons should not be broken. They can help to steer the inmate back into right path after the release and help them not fall of it. After long imprisonment the integration back into society does not happen at once. 
                </p>
                <img src="../assets/prisonProject/quotes.png" class="plant" alt=""><br><br>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Key Touchpoints</span></h5><br><br><br><br>
                <p class="left">
                  In case with Toomas, our concept provides help for the concerns and problem he has.
                  During his entrance to prison he was met with contact person who would be attached to him during his whole imprisonment. Contact person meeting is the first introduction to new environment Toomas has, he appreciates being asked of concerns of the new regulations of his life. After quick assessment he is included in social program group sessions where inmates are able to discuss news from outside world and get guidance on newest technological advancements.
                  Toomas impressions of activities and everyday struggles are monitored by contact person in a way of personal meetings in private sensory room, where he is able to openly express himself.
                  Sensory room becomes an important part of Toomas journey, since there he is able to be reminded of normal life interior and more importantly call his outside connections from there.
                  Closer to release contact person moves his attention from Toomas feelings to concerns of life after release, providing up to date information of outside world. 
                  After the release Toomas succeeds in stabilizing his life and avoids to break law ever since. He appreciates the ability to call the person he has constantly contacted for a long period of time lately, his contact person. At an edgy time he calls to express concerns and replies with appreciating feedback at the exciting ones.
                </p>
                <img src="../assets/prisonProject/RoomTouchPoints.png" class="plant" alt=""><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Contact Person V2.0</span></h5><br><br><br><br>
                <p class="left">
                  The primary objective of the contact person v2.0 is to establish and nurture meaningful relationships between inmates and their designated contacts throughout their journey within the prison system. Unlike traditional models that might focus solely on risk assessment and security measures, the contact person v2.0 shifts the emphasis towards facilitating discussions and interactions that mimic those found in the outside world. By doing so, it aims to promote successful rehabilitation and reduce recidivism rates.
                  At first, the work load of contact person must be optimized in priority, since these people hold most impact in social/rehabilitation work with inmates. This includes better regulation of report and risk assessment as well as regulated distribution of tasks between other prison staff. The possible ways of achieving it is also described in our colleges project about digitization and optimization. 
                  Secondly, for proper implementation of idea all contact persons should share a similar belief in value of their job. The value of properly conducted conversation, which can save lives in prison environment. 
                  Finally, goal of contact person job should be to create an impression of helpful environment for inmate to lead him for normal lifestyle choice. That including the after release support, where inmates would be allowed to have calls to appreciate or express struggles with contact person they long built their relationship during imprisonment.
                </p>
                <img src="../assets/prisonProject/contactPerson2.png" class="plant" alt=""><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Sensory Room</span></h5><br><br><br><br>
                <p class="left">
                  Sensory room is an addition to sectioned area of the prison. The main idea of the room is to provide a stress relieving environment with possibility of private use for inmates.
                  The environment would form around being natural and homey - providing expression of outside world cozy living room or cabinet. The functionality provided in the room would allow contacting with family online, having private time off cell neighbors, give rest of depressing environment of prison section.
                  The sensory room is an environmental change designed to support the social work improvements within the prison system. It serves as a dedicated space available for inmates during private visits, where they can engage in various activities that cater to their sensory needs. The sensory room offers a range of features and functionalities to create a therapeutic and calming environment, promoting well-being and reducing stress levels. Some of the elements commonly found in a sensory room include:
                </p>
                <img src="../assets/prisonProject/roomConcepts.png" class="plant" alt=""><br><br>
                <p class="left">The design of the sensory room is carefully crafted to create a welcoming and comforting environment for visiting inmates, evoking a sense of normalcy and home-like atmosphere. The key elements within the interior mood board include the strategic use of plants, wooden furniture, and tactile materials.
                Plants play a vital role in the sensory room, as they bring a touch of nature and tranquility to the space. Greenery has been proven to have a calming effect on individuals, reducing stress and promoting a sense of well-being.
                Wooden furniture is another essential component of the sensory room. The warm and natural tones of wood contribute to a cozy and inviting ambiance.
                Tactile materials further enhance the sensory experience within the room. Soft cushions, plush rugs, and textured fabrics are carefully selected to provide a sense of comfort and relaxation. These materials invite visitors to touch, feel, and engage with their surroundings, promoting a soothing and therapeutic effect.
                The combination of plants, wooden furniture, and tactile materials in the sensory room creates an environment that embraces thoughts of normal life for visiting inmates (see attachments - moodboard). The careful attention to detail and the incorporation of these elements aim to alleviate stress, promote a sense of comfort, and offer a respite from the institutional atmosphere of the prison. This homey atmosphere not only provides a sanctuary for individuals during their visit but also supports their emotional well-being and fosters a positive mindset for their reintegration into society.
                </p>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">User Journey</span></h5><br><br><br><br>
                <p class="left">
                  The concept of the sensory room also provides a process plan about the guidelines of the management within the room, the different options presented to the inmate, the room availability schedule and the instructions for guards and visitors.
                </p>
                <img src="../assets/prisonProject/prisonJourney.png" class="plant" alt=""><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline9"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">How we change the system</span></h5><br><br><br><br>
                <p class="left">
                  We aim to change the interactions between the inmate and contact person. In larger sense we try to change the fact that often the inmates have minimal support from the prison system, friend and family. Changing the role of the contact person by focusing on the part that already shows positive result we hope we can improve the support for the inmates in the prison system. When the contact person has more time to focus on the inmate we hope to see positive results regarding rehabilitation rates of the inmates.Another important aspect of our concept is the sensory room. We aim to change the current situation where inmates are spending almost all of their time between concrete walls and in an environment that is nothing like the living environment of a home. The sensory room aims to give the inmates a possibility of “escaping” the prison environment for a short period of time to relax and maybe have a call with their loved ones.                </p>
                <img src="../assets/prisonProject/roomEnd.png" class="plant" alt=""><br><br>
            </div>
        </div>
        <br>
        <router-link to="/fatiguetracker"><h1 class="ani9">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.headline9{
    color: #5D84C8;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani9 {
    color: #5D84C8;
}
nobr {
    color: #5D84C8;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #5D84C8;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold9{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #5D84C8;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #5D84C8;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline9{
  color: #5D84C8;
}
.hr9{
    border-width: 2.5px;
    border-color: #5D84C8;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #5D84C8; 
  border: 5px solid #5D84C8;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über9{
    color: #5D84C8;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 80%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #5D84C8;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani9{
  font-size: 50px;
  color: #5D84C8;
}
.ani9:hover{
  color: rgb(0, 0, 0);
}
.ani9::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #5D84C8;
}
.ani9:hover:after{
  color: #5D84C8;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani9{
    font-size: 25px;
  }
  .ani9:hover:after{
    color: #5D84C8;
    width: 30%;
  }
  .Über9{
    word-wrap: none;
  }
  .headline9{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold9{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr9 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr9 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline9 {
    font-size: 24px;
  }
}
</style>