<template>
  <body>
    <NavigationBar />
    <div rel="preload"> 
      <div class="bgimg-1" id="home">
        <div class="w3-display-left padding">
          <span class="header-column">
            <div class="left">
              <h1>Hi </h1>
              <h1>I am Fabienne</h1>
              <h1>and I work as a dedicated</h1>
              <h1 class="typing"></h1>
            </div>
            <div class="right">
              <img class="header-img" src="../assets/home/plantanicalApp.png">
            </div>
          </span>
        </div>
        <img src="../assets/home/downArray.svg" class="arrow" width="1%" id="disappearing-image">
      </div>
    
      <div class="content-area fade-in">
        <div class="headline-area">
          <div class="first-column">
            <h1>Bridging the gap between <span style="color: #56C288;">design</span> and <span style="color: #4D52D5;">development</span> to <b style="font-family: 'Space Grotesk', monospace; font-style: italic; font-weight: bold;">create meaningful experiences </b><b style="font-family: 'Courier Prime', monospace; font-style: italic; font-weight: bold;">&#10035;</b></h1>
            <div class="landingpage-content"><b style="font-family: 'Courier Prime'; font-weight: 400;">&#10035;</b> I transform ideas into functional and user-centered products through design thinking and prototyping.</div>
          </div>
          <div class="align-image">
            <img class="forms" src="../assets/home/forms.png">
            <!-- <div class="landingpage-content">I transform ideas into functional and user-friendly products through UX/UI design and frontend programming.</div> -->
          </div>
        </div>
        <hr>
        <div class="column-grid">
          <div>
            <div class="numbers">01</div>
            <div class="headline-numbers">Innovation <br> Concepting</div>
            <img src="../assets/home/try2.png" style="width: 70%; filter: saturate(0);">
          </div>
          <div>
            <div class="numbers">02</div>
            <div class="headline-numbers">UX & UI <br> Design</div>
            <img src="../assets/home/try1.png" style="width: 80%; filter: saturate(0);">
          </div>
          <div>
            <div class="numbers">03</div>
            <div class="headline-numbers">Frontend <br> Development</div>
            <img src="../assets/home/scribble1.png" style="width: 70%; filter: saturate(0);">
          </div>
          <div>
            <div class="numbers">04</div>
            <div class="headline-numbers">Prototyping/ <br> User Testing</div>
            <img src="../assets/home/scribble6.png" style="width: 70%; filter: saturate(0);">
          </div>
        </div>
      </div>

      <div class="bg-image3 fade-in">
        <div class="headline-area">
          <div class="first-column">
            <div class="overline">Say hi!</div>
            <h1 style="color: #D8CBC3">Have I sparked your interest?</h1>
            <a href="mailto:fabiennevatter@web.de" class="contact-button">Let's talk</a>
            <div class="shoutout">Image by <a href="https://lunakloess.de">Luna Kloess</a></div>
          </div>
        </div>
      </div>
    <FooterImpressum/>
    </div>
  </body>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
import FooterImpressum from '@/components/FooterImpressum.vue'

export default {
  name: 'aboutmeView',
  components:{
    NavigationBar,
    FooterImpressum
  },
  props: {},
  methods: {
    handleScroll() {
      // Loop through the elements and check if they are in the viewport
      this.elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          element.classList.add('active');
        }
      });
    },
  },
  mounted(){
    let text = ['Design Technologist', 'Digital Product Designer', 'Artist', 'Digital Innovator', 'UX Designer', 'Frontend Developer', 'Social Service Designer'];
    let count = 0;
    let index = 0;
    let currentText = '';
    let letter = '';

    (function type(){
      if(count === text.length){
        count = 0;
      }
      currentText = text[count];
      letter = currentText.slice(0, ++index);

      document.querySelector('.typing').textContent = letter;
      if(letter.length === currentText.length){
        count++;
        index = 0; 

        setTimeout(() => {
          type(); // Starte die nächste Zeichenanimation nach der Pause
        }, 1000); // Delay von 1 Sekunde
      } else {
        setTimeout(type, 80); // delay 200ms für die Zeichendarstellung
      }
    })();

    const image = document.getElementById('disappearing-image');

    window.addEventListener('scroll', () => {
        if (window.scrollY > 100) { // Anpassen, ab welchem Scrollwert das Bild verschwinden soll
            image.classList.add('hidden');
        } else {
            image.classList.remove('hidden');
        }
    });

    // Get all elements with the fade-in class
    this.elements = Array.from(this.$el.querySelectorAll('.fade-in'));

    // Attach the scroll event listener
    window.addEventListener('scroll', this.handleScroll);

    // Trigger the initial check
    this.handleScroll();
  },
  beforeUnmount() {
    // Remove the scroll event listener when the component is destroyed
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  --color-focus: #8DDB4D;
  --color-primary: #1C1C1C;
  --color-light: #D8CBC3;
}

h1 {
  font-size: 40px; 
  font-family: 'Avenir'; 
  font-weight: 100; 
  line-height: normal;
}

.sun {
  width: 20%;
}

.landingpage-content {
  color: var(--color-primary);
  font-family: Avenir, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  width: 80%;
}

.align-image {
  display: flex;
  justify-content: right;
}

.overline {
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  color: var(--color-focus);
}

.forms {
  width: 100%;
}

.first-column {
  display: flex;
  width: 150%;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}

.column-grid{
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 40px 80px 0px 80px;
}

.numbers {
  font-family: 'Avenir', sans-serif; 
  font-style: bold; 
  font-weight: bold;
  margin-bottom: 8px;
  color: var(--color-focus);
  text-align: center;
}
.headline-numbers {
  font-family: 'Space Mono', monospace; 
  font-style: italic;
  margin-bottom: 24px;
  text-align: center;
}

.bgimg-1{
  height: 100vh;
  width: 100%;
  text-align: center;
  background-image: url("../assets/home/backgroundImage.png");
  background-size: cover;
  background-position: center;
}

.bg-image3 {
  padding: 100px 0px 379px 0px;
  height: 95vh;
  width: 100%;
  /* background-image: url("../assets/gmundLK4.jpeg"); */
  background-image: url("../assets/home/luennsy1.jpg");
  background-size: cover;
  background-position: center;
}

.shoutout {
  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-light);
  margin-bottom: 40px;
  margin-right: 80px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.shoutout a {
  color: var(--color-light);
}

.small{
  font-size: 10px;
  color: var(--color-light);
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 34px;
  margin-bottom: 14px;
}

.padding{
  margin-top: 60px;
  margin: 0px 80px 0px 80px;
}

/* .h2{
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: Avenir, sans-serif;
  font-size: 48px;
  font-weight: lighter;
  color: var(--color-focus);
} */

.header-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.left{
  text-align: left;
  padding-bottom: 80px;
}

.right {
  width: 30%;
}

.typing{
  font-family: "Space Grotesk", monospace;
    font-weight: lighter;
    position: relative;
    margin-top: 20px;
    font-size: 40px;
    color: var(--color-focus);
}

.arrow {
  width: 2%;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  text-align: center;
}

.headline-area {
  text-align: left;
  align-items: flex-start;
  gap: 100px;
  display: flex;
  padding: 0px 0px 40px 80px;
  align-items: center;
}

hr {
  border-width: 2px;
  border-color: var(--color-focus);
}

.content-area {
  padding: 100px 0px 80px 0px;
}

#disappearing-image {
    transition: opacity 0.5s ease-in-out; /* CSS-Übergang für die Animation */
}

.hidden {
    opacity: 0; /* Bild ausblenden */
}

.contact-button {
  display: flex;
  width: 158px;
  height: 49px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 2px solid var(--color-light);
  color: var(--color-light);
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  margin-top: -40px;
}

.fade-in {
  opacity: 0;
  transition: transform 600ms ease, opacity 600ms ease;
  transform: translateY(150px)
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0px)
}

.impressum {
  background-color: white;
  padding: 40px 80px;;
  text-align: left;
}
.row-system {
  display: inline-flex;
  align-items: flex-start;
  gap: 104px;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

.shoutout a {
  text-decoration: underline;
}

.header-img {
  width: 100%;
}

@media only screen and (max-width:1200px){
  h1 {
    font-size: 40px;
    line-height: normal;
  }
}

@media only screen and (max-width:1050px){
  .padding{
    margin-left: 80px;
  }
  .align-image {
    width: 80%;
  }
  .typing {
    font-size: 35px;
  }
  h1 {
    font-size: 35px;
    line-height: normal;
  }
  .first-column {
    gap: 40px;
  }
  .headline-area {
    gap: 50px;
  }
  .landingpage-content {
    font-size: 22px;
  }
  .contact-button {
    margin-top: 0px;
  }
}

@media only screen and (max-width:900px){
  h1 {
    font-size: 26px;
    line-height: normal;
  }
  .typing {
    font-size: 26px;
  }
  .landingpage-content {
    font-size: 16px;
  }
  .align-image {
    width: 60%;
  }
}

@media only screen and (max-width:700px){
  .preload {
    overflow: hidden;
  }
  .header-column{
    flex-direction: column;
    align-items: start;
  }
  .header-img {
    width: 150%;
  }
  .headline-numbers {
    font-size: 12px;
  }
  .shoutout {
    margin-right: 40px;
  }
}

@media only screen and (max-width:550px){
  .padding {
    margin: 0px 40px 0px 40px;
  }
  .capital {
    width: 43px;
  }
  .typing {
    font-size: 24px;
  }
  .headline-area {
    padding: 0px 0px 40px 40px;
  }
  .headline-numbers {
    font-size: 12px;
  }
  .content-area {
    padding: 80px 0px 80px 0px;
  }
  .bg-image3{
    padding: 80px 0px 379px 0px;
    height: 75vh;
  }
  .bgimg-1 {
    height: 75vh;
  }
  h1 {
    font-size: 18px;
  }
  .landingpage-content {
    font-size: 12px;
  }
  .column-grid {
    grid-template-columns: 1fr 1fr;
    margin: 40px 40px 0px 40px;
  }
  .align-image{
    width: 90%;
  }
  .headline-area {
    gap: 0px;
  }
  hr {
    border-width: 2px;
  }
}
</style>

  


