<template>
    <div class="container">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Data visualization</h2>
                <h3>The presentation of data requires the use of graphic elements, static and interactive forms, colors and ordering principles to make correlations visible.</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/datenProject/data.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div><br><br>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über4">&#10035; COURSE</span><br>
                    Darstellen <br> Simulation
                </div>
                <div class="what">
                    <span class="Über4">&#10035; SEMESTER</span><br>
                    SS 2021 IoT2
                </div>
                <div class="what">
                    <span class="Über4">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über4">&#10035; TOOLS</span><br>
                    Jupyter Notebook <br> P5JS <br> Figma 
                </div>
                <div class="what">
                    <span class="Über4">&#10035; TEAM</span><br>
                    Marvin Ebert 
                </div>
              </div>  
        </div>
        <img src="../assets/info4.png" class="width" alt="">
        <br>
        <hr class="hr4">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline4"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Topic</span></h5><br><br><br><br>
                <p class="left">
                    The semester project of the lecture 'Display Simulation' deals with a dataset about traffic accidents in germany in the year 2019. The dataset is from the website of the Federal Statistical Office and consists of detailed raw data about all accidents from the mentioned year. 268,370 individual accidents were recorded by the police and for each of these accidents, information is available on the following parameters:
                    state, administrative district, county, municipality, year, month, hour, day of the week, accident category (killed, seriously injured, slightly injured), accident type (collision, rear-end collision, lane departure, ...), accident type (driving accident, turning accident, accident at crossroads), lighting conditions (daylight, twilight, darkness), accident involvement (bicycle, car, pedestrian, ...), road condition (wet, dry, slippery in winter, ...) and coordinates.
                </p><br>
                <img src="../assets/datenProject/dv-data.png" class="list" alt=""><br>
                <br><div style="color: #8660e9; text-align: center;">Raw data table</div>

                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline4"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Preparation of the data</span></h5><br><br><br><br>
                <p class="left">
                    The data from the table are processed with the library 'Pandas' in Jupyter Notebook. To group the data, the coordinates stored in the table are divided by a high number and rounded. If the X and Y coordinates are the same, it will be a box in the final graph. The new coordinates are written into a new column. In the next step, the coordinates are grouped with the months. That means that there is one row in the table per month and per box. The whole thing is now saved as a new CSV table. <br><br>
                    The CSV table is used for the visualization. The code for the visualization is written in P5JS. There are two core elements in the code: reading the CSV table and rendering. For the readout, the table is gone through once and for each row it is checked if a field with the coordinates already exists. If it already exists, it will be a different month for that field in the row that is being read. So then the accident numbers for this month are stored in the field, which has the corresponding coordinates. If the field does not exist yet, it is checked if it is a normal field or a field with no data. A no data field is created if no data has been collected. If data has been captured, a data field is created. When rendering, the list of all stored fields is scrolled through once. Since the position on the drawing area is already calculated for each field when it is created, each field can be rendered as a square to these coordinates. Here you can find the code for the visualization:<br>
                    <a class="a" href="https://editor.p5js.org/fabienne.vatter/sketches/ovudyxzsm">https://editor.p5js.org/fabienne.vatter/sketches/ovudyxzsm</a>
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline4"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Moodboard</span></h5><br><br><br><br>
                <p class="left">
                    A mood board with thematically suitable images serves as the base for determining appropriate colors.
                    Images of asphalt and highways, long exposures of a busy road, headlights in the dark, and emergency vehicles from the police, fire department and ambulance made it onto the mood board. 
                </p><br>
                <img src="../assets/datenProject/dv-mood.png" class="plant" alt=""><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline4"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Color scheme</span></h5><br><br><br><br>
                <p class="left">
                    From the derived colors, color scales are created in Figma. To be able to view the color effect and the color gradients in context, the colors are applied to the map in P5JS. Here it is important not to display the colors alone but in context, on a background. In addition, caution is required that the no-data field of Mecklenburg-Vorpommern doesn't separate from the rest of the map. It must be adapted to the color gradient of the map in such a way that it is perceived as belonging to it. The no-data field and the null field must be very similar, since they represent basically the same thing but must be distinguishable from each other. Thus, many variations were iterated to properly represent the relationship between the no-data field, null field, and the other data fields. In between, many colors are experimented with and it is quickly found that too many colors distort the meaning of the representation. In addition, certain colors that evoke false associations in the user are avoided. Red and orange colors look like fire or heat. Also, the colors blue and orange remind too much of weather forecasts. Therefore, the colors red, orange and blue are not used. The final design builds on the color purple. This color is not associated with any other theme. The scale starts with a calm gray tone that fits the theme. Then, it progresses through purple to a dark gray purple, almost black. The no-data field of Mecklenburg-Vorpommern is given a light lilac tone that matches the light zero field and the primary color purple.
                </p>
                <img src="../assets/datenProject/dv-color.png" class="plant" alt="">
                <br><div style="color: #8660e9; text-align: center;">Final color scheme</div>

                <br>
                <p class="left">
                    The minimalist font 'Hind' leaves plenty of space for data visualization. Since small font sizes were used, a lot of white space is created. This puts the main focus on the map. The font and legend move into the background. The very elegant font is used in small font sizes and in the regular font style. 
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline4"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Final visualization</span></h5><br><br><br><br>
                <p class="left">
                    In the finished prototype is a main view where all months are displayed side by side in two lines. At the top left are the title and the navigation. Below are two rows with graphics of the individual months. Under each graphic the month is written out in small letters. At the bottom left is the legend and at the bottom right the names and the subject. The legend is structured in such a way that the color scale is on the left and the no-data field is on the right. This order is chosen because the color scale has a higher priority and is therefore in first place.
                </p>
                <img src="../assets/datenProject/dv-final1.png" class="plant" alt=""><br>
                <br><div style="color: #8660e9; text-align: center;">General overview</div><br><br>
                <p class="left">
                    In the monthly view, you can see each month individually. On the right, the current month is large displayed. The navigation to switch between the individual months is on the left. For a structured navigation between the months, they are all arranged in a circle. The arrangement in a circle makes it very easy for the user to compare the months with each other. He can select a month by hovering and it will be marked with a purple circle. 
                </p>
                <img src="../assets/datenProject/dv-final2.png" class="plant" alt=""><br>
                <br><div style="color: #8660e9; text-align: center;">Monthly overview</div><br><br>
                <p class="left">
                    Switching between the main view and the monthly view, works by clicking on the respective label in the navigation. The active page is highlighted in purple.
                </p><br><br>
                <div style="padding:62.59% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/574833585?h=35629f7503&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Finale Datenvisualisierung"></iframe></div>
            </div>
        </div>
        <br><br><br><br>
        <router-link to="/remotegardening"><h1 class="ani4">Next project</h1></router-link>
        <br><br><br>
    </div>
</template>
    
<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
    name: 'datenView',
    components:{
        NavigationBar
    },
    mounted(){
    window.scrollTo(0, 0)
    }
    
}
</script>
    
<style>
.headline4{
    color: #8660e9;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani4 {
    text-align: center;
    color: #8660e9;
}
nobr {
    color: #8660e9;
    font-weight: 400;
}
.home{
  text-align: center !important;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #8660e9;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold4{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #8660e9;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #8660e9;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline4{
  color: #8660e9;
}
.hr4{
    border-width: 2.5px;
    border-color: #8660e9;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
    align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #8660e9; 
  border: 5px solid #8660e9;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über4{
    color: #8660e9;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 60%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #8660e9;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani4{
  font-size: 50px;
  color: #8660e9;
}
.ani4:hover{
  color: rgb(0, 0, 0);
}
.ani4::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #8660e9;
}
.ani4:hover:after{
  color: #8660e9;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani4{
    font-size: 25px;
  }
  .ani4:hover:after{
    color: #8660e9;
    width: 30%;
  }
  .Über4{
    word-wrap: none;
  }
  .headline4{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold4{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr4 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr4 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline4 {
    font-size: 24px;
  }
}
</style>