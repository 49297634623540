<template>
    <div class="impressum">
        <div class="overline">Let’s talk >></div><br>
        <div class="row-system" style="margin-right: 120px;">
          <div class="important">Follow me</div> 
          <div>
            <a href="https://www.linkedin.com/in/fabiennevatter/" target="_blank">LinkedIn</a><br>
            <a href="https://www.behance.net/fabiennevatter" target="_blank">Behance</a> 
          </div>
        </div>
        <div class="row-system">
          <div class="important">Contact me</div> 
          <div>
            <a href="mailto:fabiennevatter@web.de">fabiennevatter@web.de</a><br>
          </div>
        </div><br><br><br><br>
        <div class="important">© Fabienne Vatter</div> 
        <div class="important">this website is coded by myself.</div> 
    </div>
</template>

<script>
    export default {
      name: 'FooterImpressum',
      data() {
        return {
        }
      },
      methods: {
      }
    }
</script>
    
<style scoped>
    .impressum {
        background-color: white;
        padding: 40px 80px;
        text-align: left;
    }
    .row-system {
        display: inline-flex;
        align-items: flex-start;
        gap: 104px;
    }
    a{
      text-decoration: none;
      font-family: Avenir;
      font-size: 16px;
      color: var(--color-primary);
    }
    .important {
        color: var(--color-primary); 
        font-weight: 800;
        font-family: Avenir;
        font-size: 16px;
    }
    .overline {
        font-family: Avenir;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        color: var(--color-focus, #8DDB4D);
    }
    @media screen and (max-width: 850px) {
      .important {
        width: 200px;
      }
    }
    @media screen and (max-width: 730px) {
      .row-system {
        gap: 0px !important;
      }
      .important {
        width: 230px;
        font-size: 14px;
      }
      a {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 550px) {
      .impressum {
        padding: 40px !important;
      }
      .important {
        width: 150px;
        font-size: 12px;
      }
      a {
        font-size: 12px;
      }
    }
</style>