<template>
  <div class="aboutme">
    <NavigationBar />
    <div class="content-area fade-in">
      <div class="headline-area">
        <div class="first-column">
          <div class="overline">Hi that's me</div>
          <h1 style="margin-block-end: 0px;">My passions are traveling, scouting and being creative</h1>
          <h4>I just completed my Bachelor in <b>Digital Product Design and Development</b> at the Hochschule für Gestaltung in Schwäbisch Gmünd with a 1,1 grade.
          Additionally, I work as a working student at bbg bitbasegroup in the UX Design apartment.<br><br>
          In my free time I like to be in nature. I have been a member of the Scouts since I was a child. In 2018, I started to lead a group of children on a voluntary basis.
          I love creating new things and face challenges, so I've traveled New Zealand on my own for three months and added a new terrace to my parents' garden in my year off.
          </h4>
        </div>
      </div>
    </div>
   
    <div class="w3-row" id="myGrid">
      <div class="w3-third">
        <img rel="preload" src="../assets/aboutme/r1.jpg" style="width:90%" class="pad">
      </div>
      <div class="w3-third">
        <img rel="preload" src="../assets/aboutme/bwam1.png" style="width:90%" class="pad">
         <!-- in color: <img rel="preload" src="../assets/r3.jpg" style="width:90%" class="pad"> -->
      </div>
      <div class="w3-third">
        <img rel="preload" src="../assets/aboutme/bwam2.png" style="width:90%" class="pad">
        <!-- in color: <img rel="preload" src="../assets/dive.jpg" style="width:90%" class="pad">  -->
      </div>
    </div><br><br>

    <div class="content-area fade-in">
      <div class="headline-area" style="margin-block-end: 24px;">
        <div class="first-column">
          <div class="overline">What I've done so far</div>
          <h1>Curriculum Vitae</h1>
        </div>
      </div>
      <div class="section">
        <div class="section-headline">Educational Path</div>
        <div class="cv-list">
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="path-steps">
              <div class="step-2">
                <div class="path-step-headline">Bachelor of Arts</div>
                <div class="path-step-subline">10/2020 - 08/2024</div>
                <div class="path-step-discription">
                  Digital Product Design and Development <br>
                  University of Design Schwäbisch Gmünd <br>
                  Completed with 1,1 
                </div>
              </div>
            </div>
          </div>
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="path-steps">
              <div class="step-1">
                <div class="path-step-headline">Semester abroad</div>
                <div class="path-step-subline">01/2023 - 06/2023 · 6 months</div>
                <div class="path-step-discription">
                  Design and Technology Futures Master <br>
                  Estonian Academy of Arts <br>
                  TalTech – Tallinn University of Technology 
                </div>
            </div>
            </div>
          </div>
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="path-steps">
              <div class="step-3">
                <div class="path-step-headline">General University Entrance Qualification</div>
                <div class="path-step-subline">07/2019</div>
                <div class="path-step-discription">
                  Isolde-Kurz-Gymnasium <br>
                  Reutlingen   
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br><br>
      <div class="section">
        <div class="section-headline">Professional <br> Experience</div>
        <div class="cv-list">
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="path-steps">
              <div class="step-1">
              <div class="path-step-headline">Service Designer</div>
              <div class="path-step-subline">01/2024 - Now · 7 months</div>
              <div class="path-step-discription">
                bbg bitbase group GmbH <br>
                Working student · Remote 
              </div><br>
              <div class="path-step-subline">06/2023 - 08/2023 · 2 months</div>
              <div class="path-step-discription">
                bbg bitbase group GmbH <br>
                Fulltime · On-site in Reutlingen
              </div>
            </div>
            </div>
          </div>
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="path-steps">
              <div class="step-2">
              <div class="path-step-headline">UX Designer and Frontend Developer</div>
              <div class="path-step-subline">08/2022 - 01/2023 · 6 months</div>
              <div class="path-step-discription">
                DieProduktMacher <br>
                Fulltime Internship · On-site in Munich
              </div>
            </div>
            </div>
          </div>
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="step-3">
              <div class="path-step-headline">Service and UX/UI Designer</div>
              <div class="path-step-subline">10/2021 - 06/2022 · 9 months</div>
              <div class="path-step-discription">
                bbg bitbase group GmbH <br>
                Working student · Remote 
              </div>
            </div>
          </div>
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="step-4">
              <div class="path-step-headline">Intern in the field of photography</div>
              <div class="path-step-subline">11/2016</div>
              <div class="path-step-discription">
                JODANE Film production & photo studio <br>
                Internship · On-site in Reutlingen
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br><br>
      <div class="section">
        <div class="section-headline">Voluntary <br>Engagement</div>
        <div class="cv-list">
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="path-steps">
              <div class="step-1">
                <div class="path-step-headline">Group management</div>
                <div class="path-step-subline">2018 - 2022</div>
                <div class="path-step-discription">
                  Group leadership of the Young Scouts in <br>
                  “Stamm Reutlingen North” of the DPSG
                </div>
              </div>
            </div>
          </div>
          <div class="path-section">
            <img class="sun" rel="preload" src="../assets/aboutme/sun.png">
            <div class="path-steps">
              <div class="step-2">
              <div class="path-step-headline">Students Magazine “Format”</div>
              <div class="path-step-subline">2020</div>
              <div class="path-step-discription">
                Layout and article creation 
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-area fade-in">
      <div class="headline-area" style="margin-block-end: 24px;">
        <div class="first-column">
          <div class="overline">Explore more</div>
          <h1>What defines me</h1>
        </div>
      </div>
      <div class="explore-section">
        <img src="../assets/aboutme/teamwork.png" class="image-explore" justify="center" style="width: 400px;">
        <div class="text-section">
          <div class="definition-headline">Interdisciplinary teamwork</div>
          <div class="description">
            I enjoy working in a team, together with others. In doing so, I often act as a leading team member, trying to 
            motivate others and struture the work. Above all, it is important to me to work in an interdisciplinary way, to 
            collaborate with different disciplines and to learn new approaches, ways of thinking and methods.
          </div>
        </div>
      </div>
      <div class="explore-section">
        <div class="text-section">
          <div class="definition-headline" style="text-align: right !important;">Enthusiasm</div>
          <div class="description right" style="text-align: right;">
            I like to think outside the box and of a bigger picture. I'm a free spirit. I write poetry slams to express my thoughts and 
            feelings. I love being in nature and have a gift for admiring the little things. I like to be in company, among my family and 
            friends. I live out my creative streakwhile enjoying the freedom to go camping, traveling, and exploring my home.
          </div>
        </div>
        <img src="../assets/aboutme/enthusiasm.png" class="image-explore right-align" style="width: 400px;">
      </div>
      <div class="explore-section">
        <img src="../assets/aboutme/problemsolving.png" class="image-explore" style="width: 400px;">
        <div class="text-section">
          <div class="definition-headline">Vibrant problem solving</div>
          <div class="description">
            I often challenge myself and try to solve problems that concern me personally. In doing so, I take a very practical approach.
            I like to approach things in a very hands-on, solution-oriented way and I am not afraid of hard work. For example, I have sold
            ice cream, burgers and magazines. I've picked up some heavy equipment in the Scouts to set up tents, hammock holders, swings, 
            saunas, or light fires.
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="blueSection">
      <h2 class="h2">What defines me</h2>
      <div class="row">
        <div class="col-11">
          <img src="../assets/scribble7.png" class="imageme0"><br>
          <nobr class="head">Interdisciplinary teamwork</nobr><br><br>
          <div class="text">
            I enjoy working in a team, together with others. In doing so, I often act as a <b>leading team member</b>, trying to motivate others and struture the work. Above all, it is important to me to work in an <b>interdisciplinary way</b>, to collaborate with different disciplines and to learn new approaches, ways of thinking and methods.<br><br>
          </div>
        </div>
        <div class="col-11">
          <img src="../assets/scribble8.png" class="imageme2"><br>
          <nobr class="head">Vibrant problem solving</nobr><br><br>
          <div class="text">
            I often challenge myself and try to solve problems that concern me personally. In doing so, I take a very <b>practical approach</b>. I like to approach things in a very hands-on, solution-oriented way and I am not afraid of <b>hard work</b>. For example, I have sold ice cream, burgers and magazines. I've picked up some heavy equipment in the Scouts to set up tents, hammock holders, swings, saunas, or light fires.<br><br>
          </div>
        </div>
        <div class="col-11">
          <img src="../assets/scribble9.png" class="imageme"><br>
          <nobr class="head">Enthusiasm</nobr><br><br>
          <div class="text">
            I like to think outside the box and of a bigger picture. I'm a free spirit. I write <b>poetry slams</b> to express my thoughts and feelings. I love being in nature and have a gift for admiring the little things. I like to be in company, among my family and friends. I live out my <b>creative streak</b> while enjoying the freedom to go camping, traveling, and exploring my home.<br><br>
          </div>
        </div>
      </div>
    </div> -->
    
    <!-- <br><br><br><br>
    <h2>Programs & Knowledge</h2><br><br><br>
    <el-row :gutter="100" class="align">
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/figma1.png" class="image"><br></span>
      </el-col>
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/xd2.png" class="image"><br></span>
      </el-col>
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/ai1.png" class="image"><br></span>
      </el-col>
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/github1.png" class="image"><br></span>
      </el-col>
      <el-col :span="2.5" class="what">
        <span class="myID"><img src="../assets/vscode.webp" class="image"><br></span>
      </el-col>
    </el-row><br><br>
    <el-row :gutter="100" class="align">
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/python1.png" class="image"><br></span>
      </el-col>
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/html.png" class="image"><br></span>
      </el-col>
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/css.png" class="image"><br></span>
      </el-col>
      <el-col :span="2" class="what">
        <span class="myID"><img src="../assets/js2.png" class="image"><br></span>
      </el-col>
      <el-col :span="0.5" class="what">
        <span class="myID"><img src="../assets/arduino1.png" class="image"><br></span>
      </el-col>
    </el-row> -->
  </div>
  <hr>
  <FooterImpressum />
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
import FooterImpressum from '@/components/FooterImpressum.vue'

export default {
  name: 'aboutmeView',
  components: {
    NavigationBar,
    FooterImpressum
  }
}
</script>

<style scoped>
*{
  --color-focus: #8DDB4D;
  --color-primary: #1C1C1C;
  --color-light: #D8CBC3;
}

hr {
  border-width: 2px;
  border-color: var(--color-focus);
}

.cv-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.definition-headline {
  font-family: "Space Grotesk";
  font-size: 40px;
  line-height: 45px;
  color: var(--color-focus);
  text-align: left;
  margin-block-end: 24px;
}

.description {
  text-align: left;
  color: var(--text-color-black, #1C1C1C);
  /* project tile/h4 headline */
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 719px;
}

.explore-section {
  margin-inline-start: 80px;
  margin-inline-end: 80px;
  display: inline-flex;
  align-items: flex-start;
  gap: 60px;
  margin-block-end: 80px;
}

/* curriculum vitae */
.section { 
  display: flex;
  align-items: flex-start;
  gap: 300px;
  margin-inline-start: 80px;
  margin-inline-end: 60px;
  background-color: white !important;
  padding: 0px;
}
.path-lines {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}  
.path-steps {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 59px;
}
.section-headline {
  color: var(--light-theme, #D8CBC3);
  /* h2 focus headline */
  font-family: "Space Grotesk";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  width: 280px;
}
.path-step-headline {
  color: var(--focus-color, #8DDB4D);
  /* project page/headline content */
  font-family: Avenir;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-bottom: 12px;
}
.path-step-subline {
  color: var(--text-color-black, #1C1C1C);
  /* project tile/subheadline */
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-block-end: 12px;
}
.path-step-discription {
  color: var(--text-color-black, #1C1C1C);
  /* project tile/h4 headline */
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}
.path-section {
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

/* end */

.content-area {
  padding: 50px 0px 100px 0px;
}

.headline-area {
  text-align: left;
  align-items: flex-start;
  gap: 100px;
  display: flex;
  padding: 0px 80px;
  align-items: flex-start;
  gap: 250px;
}

.first-column {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px; /* it has been 80px before */
}

.overline {
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: var(--color-focus);
}
.w3-row{
  margin-bottom: 128px;
  padding: 0 80px;
}
*{
  margin: 0;
  padding: 0;
  box-shadow: border-box;
  outline: none;
}
el-col{
  padding-left: 50px;
}
h1 {
  font-family: "Space Grotesk";
  width: 60%;
  text-align: left;
  flex-wrap: nowrap;
  color: var(--text-color-black, #1C1C1C);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline 3px;
  text-underline-offset: 5px;
  margin-block-end: 80px;
}
h2{
  font-weight: bold;
  font-family: Avenir, sans-serif;
  color: black;
}
h4{
  color: var(--color-primary);
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  max-width: 949px;
}
.body{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Avenir, sans-serif;
  min-height: 100vh;
  padding-left: 50px;
}
a{
  color: black;
    text-decoration: none;
}
.sun {
    width: 9%;
}


@media only screen and (max-width:1350px){
  .explore-section {
    margin-inline-start: 80px;
    margin-inline-end: 80px;
  }
  .description {
    font-size: 20px;
    width: 90%;
  }
  .right {
    margin-inline-end: 80px;
    width: 100%;
  }
  .section-headline {
    width: 200px;
  }
}
@media only screen and (max-width:1220px){
  .line1 {
    width: 5px !important;
    height: 207px !important;
  }
}
@media only screen and (max-width:1100px){
  .section {
    gap: 130px;
  }
  img {
    width: 30%;
  }
  .description {
    font-size: 16px;
  }
  .section-headline {
    width: 200px;
  }
  .line1 {
    height: 166px !important;
  }
  .sun {
    width: 9%;
  }
}
@media only screen and (max-width:1050px){
  .line1 {
    height: 208px !important;
  }
  h1 {
    width: 100%;
  }
}
@media only screen and (max-width:990px){
  .line1 {
    height: 207px !important;
  }
  .explore-section {
    gap: 40px;
  }
}
@media only screen and (max-width: 930px) {
  .definition-headline {
    font-size: 32px;
  }
  .image-explore {
    width: 300px !important;
  }
  .line3 {
    height: 160px !important;
  }
  .section {
    display: block;
  }
  .section-headline {
    margin-block-end: 32px;
  }
  .line1 {
    height: 165px !important;
  }
  h1 {
    font-size: 40px;
  }
  .path-step-headline {
    font-size: 28px;
  }
  .sun {
    width: 5%;
  }
}
@media only screen and (max-width:730px){
  .explore-section {
    display: grid;
    gap: 40px;
  }
  .right-align {
    justify-self: right;
  }
  .image-explore {
    order: 1;
    width: 240px !important;
  }
  .definition-headline {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .first-column {
    width: 100%;
  }
  h1 {
    margin-block-end: 40px;
    font-size: 40px;
  }
  .path-step-headline {
    font-size: 24px;
  }
  .path-step-discription {
    font-size: 16px;
  }
  el-row{
    width: 100%;
    flex-direction: column-reverse;
    margin: 50px 0;
  }
  el-col{
    width: 30%;
    text-align: center;
  }
  .pad{
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 15px;
  }
  h5{
    font-size: 15px;
  }
  .line4 {
    height: 193px !important;
    width: 13px !important;
  }
  .line5 {
    height: 140px !important;
    width: 3px !important;
  }
  .line6 {
    height: 171px !important;
    width: 13px !important;
  }
  .line1 {
    height: 176px !important;
    width: 3px !important;
  }
  .line3 {
    height: 140px !important;
    width: 3px !important;
  }
  .line7 {
    height: 170px !important;
  }
  .sun {
    padding-top: 3px;
  }
}
@media only screen and (max-width:550px){
  .headline-area {
    padding: 0px 40px;
  }
  h1 {
    text-decoration: underline 2px;
    text-underline-offset: 4px;
    font-size: 26px;
  }
  h4 {
    font-size: 14px;
  }
  .content-area {
    padding: 50px 0px 40px 0px;
  }
  .w3-row{
    margin-bottom: 0px;
  }
  .section-headline{
    font-size: 26px;
  }
  .defintion-headline{
    font-size: 24px;
  }
  .section {
    margin-inline-start: 40px;
  }
  .explore-section {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
  .sun {
    padding-top: 8px;
  }
}

</style>