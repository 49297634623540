<template>
  <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Plantanical</h2>
                <h3>With Plantanical, you'll never again have to entrust your front door key to the
                  neighbors and ask if they can take care of your plants.</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/plantanicalProject/p11.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über2">&#10035; COURSE</span><br>
                    Visual <br> Prototyping
                </div>
                <div class="what">
                    <span class="Über2">&#10035; SEMESTER</span><br>
                    SS 2021 IoT2
                </div>
                <div class="what">
                    <span class="Über2">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über2">&#10035; TOOLS</span><br>
                    Figma <br> Miro 
                </div>
                <div class="what">
                    <span class="Über2">&#10035; TEAM</span><br>
                    Tim Henrik Seib <br> Nyal Hettmer
                </div>
              </div>  
        </div>
        <hr>
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <div class="left">
                  <h4 class="einleitung">Plantanical digitizes your garden, allowing you to monitor and maintain your sensor-connected plants anytime, anywhere. Plantanical gives you the tools you need to effectively reinvent gardening! </h4>
                </div>
                <p class="left">
                  With the mobile app, you can add plants, connect them to sensors, enter relevant information, and water the plants with an automatic or manual water schedule. 
                  But Plantanical is much more than just a monitoring app! Plantanical takes you into the world of plants and allows you to become a real Plantanical guru. Scan your environment and learn which plants surround you. Read interesting articles and learn what's driving the plant world right now. Be hip and sustainable: How much water and electricity do your plants use? How are your plants doing? And how have your plants evolved in their life cycle?</p>
                <br>
                <div style="padding:65.89% 0 0 0;position:relative;">
                  <iframe src="https://player.vimeo.com/video/711182523?h=058d9f7151&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="screenrecord">
                  </iframe>
                </div>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Desinging a good user experience</span></h5><br><br><br><br>
                <div class="left">
                  <div class="bold2">Accessibility</div> Discoverability, technical compatibility, responsiveness, subjectively perceived performance <br><br>
                  <div class="bold2">Utility</div> Subjective utility value of the content and functions for the target group addressed<br><br>
                  <div class="bold2">Usability</div> Effective, efficient and simple usability of the medium on the part of the users (user-friendliness)<br><br>
                  <div class="bold2">Joy of Use</div> Subjectively perceived pleasure before, during and after use<br><br>
                </div>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Ideation</span></h5><br><br><br><br>
                <p class="left">
                  We gathered key information about similar applications and wrote them down in notes. Then we doodled rough solutions and ideas on paper. With the method 'Crazy 8', we tried rapid variations in a short amount of time. After figuring out the details, we did a solution sketch in form of a wireflow. With the help of a dot voting we saw what elements we liked of each other and what we want to adopt for our project.
                </p>
                <img src="../assets/plantanicalProject/plant3.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Wireframes</span></h5><br><br><br><br>
                <p class="left">
                  Wireframing is an essential step in the process of designing an interface. So we tried to visualise our information architecture, the placement of our elements and the presentation of the interactions. That helped us to make a clear structure and to determine the layout and the hierarchy.   
                </p>
                <img src="../assets/plantanicalProject/plant4.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Visual research</span></h5><br><br><br><br>
                <p class="left">
                  During visual research, we focused on the presentation of similar applications. How do others design related interfaces and which elements can we adopt and improve? It's all about form, colour, interactions, functional elements and the implementation of the problem. 
                </p>
                <img src="../assets/plantanicalProject/plant6.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Moodboard</span></h5><br><br><br><br>
                <p class="left">
                  After that, we created a moodboard with colours and sentiments we want to transport. We also did a Styleguide to ensure a consistent design trough the application. We decided on a font, font sizes, colors and an icon set that fit to our idea of what we wanted to design.
                  Now we could all work on the project without it ending up looking like three different styles.
                </p>
                <img src="../assets/plantanicalProject/plant7.png" class="plant" alt="">
                <img src="../assets/plantanicalProject/plant9.png" class="plant" alt="">
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Low Fidelity</span></h5><br><br><br><br>
                <img src="../assets/plantanicalProject/lofi.png" class="plant" alt="">
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Mid Fidelity</span></h5><br><br><br><br>
                <img src="../assets/plantanicalProject/plant8.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline2"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">High Fidelity</span></h5><br><br><br><br>
                <img src="../assets/plantanicalProject/carousel4.png" class="plant" alt="">
            </div>
        </div>
        <br>
        <router-link to="/wiwa"><h1 class="ani2">Next project</h1></router-link>
        <br>
    </div>  
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #71ac4e;
}
h4{
  margin-top: 0px;
}
h5 {
  color: #71ac4e;
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold2{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #71ac4e;
}
.einleitung{
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #71ac4e;
  font-family: Avenir, sans-serif;
}
.headline2{
  color: #71ac4e;
}
hr{
    border-width: 2.5px;
    border-color: #71ac4e;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  /* margin-left: 50px; */
  text-align: left;
}
.col-2 .controller{
  /* padding-left: 8px; */
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #71ac4e; 
  border: 5px solid #71ac4e;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über2{
    color: #71ac4e;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 60%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #71ac4e;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani2{
  font-size: 50px;
  color: #71ac4e;
}
.ani2:hover{
  color: rgb(0, 0, 0);
}
.ani2::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #71ac4e;
}
.ani2:hover:after{
  color: #71ac4e;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani2{
    font-size: 25px;
  }
  .ani2:hover:after{
    color: #71ac4e;
    width: 30%;
  }
  .Über{
    word-wrap: none;
  }
  .headline2{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold2{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    /* column-gap: 16px; */
    gap: 20px 40px;
    /* justify-content: center; */
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  hr {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  hr {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline2 {
    font-size: 24px;
  }
}
</style>