<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<style>
@font-face {
  font-family: "Courier Prime";
  src: local("Courier Prime"),
  url(./fonts/Courier_Prime/CourierPrime-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Space Mono";
  src: local("Space Mono Regular"),
  url(./fonts/Space_Mono/SpaceMono-Regular.ttf) format("truetype");
  font-family: "Space Mono";
  src: local("Space Mono Bold Italic"),
  url(./fonts/Space_Mono/SpaceMono-BoldItalic.ttf) format("truetype");
  font-family: "Chonburi";
  src: local("Chonburi"),
  url(./fonts/Chonburi/Chonburi-Regular.ttf) format("truetype");
  font-family: "Space Grotesk";
  src: local("Space Grotesk Light"),
  url(./fonts/Space_Grotesk/static/SpaceGrotesk-Regular.ttf) format("truetype");
}
#app {
  font-family: Avenir, Courier Prime, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  /* margin-top: 10px; */
}
nav {
  padding: 2% 6%;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
</style>
