<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Zebra</h2>
                <h3>A digital tool for the intuitive creation of live audio descriptions for theatre performances, which provides blind and sighted creators with all the important tools they need.</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/zebraProject/zebraHeader.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über1">&#10035; COURSE</span><br>
                    Bachelor <br> Thesis 
                </div>
                <div class="what">
                    <span class="Über1">&#10035; SEMESTER</span><br>
                    SS 2024 IoT8
                </div>
                <div class="what">
                    <span class="Über1">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über1">&#10035; TOOLS</span><br>
                    Figma <br> ProtoPie 
                </div>
                <div class="what">
                    <span class="Über1">&#10035; TEAM</span><br>
                    Joschua Rothenbacher <br> Nyal Hettmer
                </div>
              </div>  
        </div>
        <hr>
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline1"><span charset="utf-8" style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px;">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <div class="left">
                  <p class="left">Zebra is an innovative tool for creating live audio descriptions for theaters. It offers blind and sighted authors the necessary tools for an efficient creation process and thus makes an important contribution to inclusion.</p>
                </div>
                <div style="padding:62.43% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/990196691?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:99%;" title="Zebra_Showcase_Video"></iframe></div>
                <br><p  style="color: #1B57F3">Das Video- und Skriptmaterial aus unserem Use-Case-Video stammt aus dem Stück “Cabaret” vom Schauspiel Stuttgart, welches uns für hochschulinterne und studentische Zwecke zur Verfügung gestellt wurde. Die Live-Audiodeskription wurde von Florian Eib und Tomke Koop von HörMal Audiodeskription eingesprochen. Sie haben uns außerdem ihr Audiodeskriptions-Skript zum Stück “Cabaret” zur Verfügung gestellt.</p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline1"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Our inspiration</span></h5><br><br><br><br>
                <p class="left">
                  With this bachelor's thesis, we want to address a social problem and enable disadvantaged people to participate in cultural goods. For over 2500 years, theaters have been central places of public representation and cultural tradition. Despite its importance as a social art form, many people remain excluded due to semiotic barriers, which limits their participation. We have identified the creation of audio descriptions as the greatest lever for promoting cultural inclusion. With Zebra, we want to make our contribution to an inclusive future in order to revolutionize the theater scene in the long term.
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline1"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">How it works</span></h5><br><br><br><br>                <p class="left">
                  The basis of Zebra is the merging of a rehearsal video with the associated script, which is linked by an algorithm and analyzed for speech pauses. All views are based on this synchronization, whereby the requirements and functions vary greatly depending on the user role. In the editing view, sighted authors have the option of adding audio description blocks and using comprehensive editing functions. AI functions such as a writing assistant and word help increase efficiency. The editing view optimized for blind and visually impaired users on screen readers makes it possible to check the process and practice audio descriptions. The dark view is ideal for theater environments. Finally, there is the live view, in which speakers perform the audio descriptions live during a rehearsal or performance. Zebra promotes the exchange between authors through notes and comments.
                </p>
                <img src="../assets/zebraProject/zebraEdit.jpg" class="plant" alt=""><br><br>
                <div style="color: #1B57F3">Edit-View</div><br><br><br>
                <img src="../assets/zebraProject/zebraRedaktion2.jpg" class="plant" alt=""><br><br>
                <div style="color: #1B57F3">Verification-View</div><br><br><br>
                <img src="../assets/zebraProject/zebraLive.jpg" class="plant" alt=""><br><br>
                <div style="color: #1B57F3">Live-View</div><br><br><br>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline1"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Design Process</span></h5><br><br><br><br>
                <p class="left">
                  Our bachelor's project is based on social service design. It was important to us to choose a topic with social relevance and real impact. By applying design methods, we delved deep into the topic and identified many possible solutions in an initial design sprint. In our deliberation process, it turned out that audio description was the best way to reduce barriers. We observed, gathered empirical experience and conducted numerous interviews with people from the theater world, audio description producers and blind and visually impaired people. Our aim was to design not only for them, but also with them. We derived requirements from the diverse insights and made important design decisions for our application. We identified the strengths and defined the unique selling point. With a mature concept, we were able to design the digital application from the core down to the last detail. User journeys and real application scenarios helped us to create the information architecture and through iterative work we developed screen flows that shaped the product from low fidelity sketches to the final prototype.
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline1"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">How it is different</span></h5><br><br><br><br>
                <p class="left">
                  The current audio description (AD) creation process is very timeconsuming and cost-intensive, which is why there are hardly any offers for performances with AD. Zebra is the first tool designed specifically for live audio description in theaters and offers a tailored solution for this application. Zebra enables a more efficient creation process, allowing more ADs to be created in less time. By combining different tools, cognitive stress is reduced and time-consuming tasks such as analyzing speech pauses are automated. Zebra integrates AI-based functions and the synchronization of script and video, which significantly improves efficiency and precision. The involvement of blind and visually impaired people ensures that ADs are created close to the needs of the end users and qualitative descriptions are guaranteed.
                </p>
                <img src="../assets/zebraProject/zebraMockup2.jpg" class="plant" alt="">
                <br><br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline1"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Future Plans</span></h5><br><br><br><br>
                <p class="left">
                  The next step is the functional development of the application. The processes previously tested for usability are tested in real scenarios and iteratively optimized so that the application is ready for the market. The aim is to establish Zebra as the industry standard for audio description. As part of the UN Convention on the Rights of Persons with Disabilities adopted in 2006, art and culture must be accessible to all people. Zebra is therefore an important tool for reducing barriers and enabling access to theater as a cultural asset. Zebra lays an important foundation for cultural participation.
                </p>
                <img src="../assets/zebraProject/zebraTeam.jpg" class="plant" alt=""><br><br>
                <div style="color: #1B57F3">Rothenbacher, Nyal Hettmer, me</div>
                <br><br><br>
            </div>
        </div>
        <br>
        <router-link to="/plantanical"><h1 class="ani1">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #1B57F3;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.headline1{
  color: #1B57F3;
}
hr{
    border-width: 2.5px;
    border-color: #1B57F3;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über1{
    color: #1B57F3;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 60%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section1{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #1B57F3;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani1{
  font-size: 50px;
  color: #1B57F3;
}
.ani1:hover{
  color: rgb(0, 0, 0);
}
.ani1::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #1B57F3;
}
.ani1:hover:after{
  color: #1B57F3;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani1{
    font-size: 25px;
  }
  .ani1:hover:after{
    color: #1B57F3;
    width: 30%;
  }
  /* .width{
    margin-top: 0px;
    padding-left: 18px;
    display: unset;
    width: 90%;
  } */
  .Über1{
    word-wrap: none;
  }
  .headline1{
    text-align: left;
    font-size: 25px;
  }
  .left{
    font-size: 15px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    /* column-gap: 16px; */
    gap: 20px 40px;
    /* justify-content: center; */
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  /* .el-col-4{
    max-width: 100px;
  } */
  /* .el-row{
    max-width: 100px;
  } */
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  /* .respons{
    display: none;
    word-break: none;
    flex-direction: column;
    column-width: 600px;
    columns: 2 200px;
    column-gap: 20px;
  } */
  .controller{
    width: 80%;
    /* margin-left: 60px; */
  }
  .color-box1{
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(#1B57F3, #08329b);
    border-radius: 20px 0 0 20px;
    height: 100%;
    width: 70%;
    z-index: -1;
    transform: translate(150px);
  }
  h3 {
    font-size: 16px;
  }
  hr {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  hr {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline1 {
    font-size: 24px;
  }
}
</style>