import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import installElementPlus from './plugins/element'
import Embed from 'v-video-embed'

const app = createApp(App)
installElementPlus(app)
app
.use(router)
.use(Embed)
.mount('#app')