<template>
  <div class="work">
    <NavigationBar />
    <div class="body">
      <div class="labels">
        <div class="label" :class="{ active: category.isActive }" v-for="category in categories" :key="category.id" @click="showProjectsInCategory(category.label)">
          <div class="label-text">{{ category.label }}</div>
        </div>
      </div>

      <div class="mobile">
        <el-row :gutter="20" class="row">
          <el-col :span="7" class="column" v-for="project in filteredProjects" :key="project.id">
            <div class="zoom" v-if="selectedProject">
              <div class="text">
                <h3>{{ project.name }}</h3>
                <p class="underline">{{ project.description }}</p>
              </div>
              <router-link :to="`/${project.route}`"><img :src="require(`../assets/${project.image}`)" class="image2"></router-link>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="row">
        </el-row>
        <el-row :gutter="20" class="row">
        </el-row>
        <el-row :gutter="20" class="row">
        </el-row>
        <br><br><br>
      </div>
    </div>

    <div class="content-area">
      <div class="headline-area" style="margin-block-end: 24px;">
        <div class="first-column">
          <h2>Main Expertise</h2>
        </div>
      </div>
      <div class="accordion-list">
        <div class="accordion-item">
          <div class="accordion-trigger" @click="toggleAccordion(1)" :aria-expanded="isOpen1" :aria-controls="`collapse${uid}`">
            <div class="expertise-text">
              <div class="expertise-text-number">01</div>
              <div class="expertise-text-description">UX and UI Design</div>
            </div>
            <img class="expertise-fold-icon" rel="preload" :class="{'rotate-180': isOpen1, 'rotate-0': !isOpen1,}" src="../assets/aboutme/sun.png">
          </div>
          <div class="accordion-content" v-show="isOpen1">
            <div class="accordion-content-text">
              The user should always have an <b>unique experience</b> by using the product. So I focus on clear, transparent, accessible, 
              effective and user-friendly designs including 'Gamefication' and interactive elements to create <b>joy of use</b>.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-trigger" @click="toggleAccordion(2)" :aria-expanded="isOpen2" :aria-controls="`collapse${uid}`">
            <div class="expertise-text">
              <div class="expertise-text-number">02</div>
              <div class="expertise-text-description">Innovation and Social Service Design</div>
            </div>
            <img class="expertise-fold-icon" rel="preload" :class="{'rotate-180': isOpen2, 'rotate-0': !isOpen2,}" src="../assets/aboutme/sun.png">
          </div>
          <div class="accordion-content" v-show="isOpen2">
            <div class="accordion-content-text">
              I enjoy working in a team, together with others. In doing so, I often act as a leading team member, 
              trying to motivate others and struture the work. Above all, it is important to me to work in an 
              interdisciplinary way, to collaborate with different disciplines and to learn new approaches, ways 
              of thinking and methods.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-trigger" @click="toggleAccordion(3)" :aria-expanded="isOpen3" :aria-controls="`collapse${uid}`">
            <div class="expertise-text">
              <div class="expertise-text-number">03</div>
              <div class="expertise-text-description">Prototyping Engineer</div>
            </div>
            <img class="expertise-fold-icon" rel="preload" :class="{'rotate-180': isOpen3, 'rotate-0': !isOpen3,}" src="../assets/aboutme/sun.png">
          </div>
          <div class="accordion-content" v-show="isOpen3">
            <div class="accordion-content-text">
              Especially with complex solutions, it is important to make them <b>tangible and understandable</b> through prototypes.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-trigger" @click="toggleAccordion(4)" :aria-expanded="isOpen4" :aria-controls="`collapse${uid}`">
            <div class="expertise-text">
              <div class="expertise-text-number">04</div>
              <div class="expertise-text-description">Fullstack Development</div>
            </div>
            <img class="expertise-fold-icon" rel="preload" :class="{'rotate-180': isOpen4, 'rotate-0': !isOpen4,}" src="../assets/aboutme/sun.png">
          </div>
          <div class="accordion-content" v-show="isOpen4">
            <div class="accordion-content-text">
              Generative design requires <b>fullstack development</b> as well as using different code variants to work with data and 
              generate visualizations, web applications, machine learning and software projects.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-trigger" @click="toggleAccordion(5)" :aria-expanded="isOpen5" :aria-controls="`collapse${uid}`">
            <div class="expertise-text">
              <div class="expertise-text-number">05</div>
              <div class="expertise-text-description">Design Methods</div>
            </div>
            <img class="expertise-fold-icon" rel="preload" :class="{'rotate-180': isOpen5, 'rotate-0': !isOpen5,}" src="../assets/aboutme/sun.png">
          </div>
          <div class="accordion-content" v-show="isOpen5">
            <div class="accordion-content-text">
              To develop an innovative and digital product, I work with design methods in an <b>iterative work process</b>. 
              Design Thinking and Doing is a mindset and that is what I base my work on.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-trigger" @click="toggleAccordion(6)" :aria-expanded="isOpen6" :aria-controls="`collapse${uid}`">
            <div class="expertise-text">
              <div class="expertise-text-number">06</div>
              <div class="expertise-text-description">Sustainability and Data-privacy</div>
            </div>
            <img class="expertise-fold-icon" rel="preload" :class="{'rotate-180': isOpen6, 'rotate-0': !isOpen6,}" src="../assets/aboutme/sun.png">
          </div>
          <div class="accordion-content" v-show="isOpen6">
            <div class="accordion-content-text">
              Not just analog products should be <b>ethically justifiable</b> but also digital products. I have the claim that a product 
              should always be sustainable, fair and ethical responsible. There should always be considerations about these topics.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-trigger" @click="toggleAccordion(7)" :aria-expanded="isOpen7" :aria-controls="`collapse${uid}`">
            <div class="expertise-text">
              <div class="expertise-text-number">07</div>
              <div class="expertise-text-description">User Research & Testing</div>
            </div>
            <img class="expertise-fold-icon" rel="preload" :class="{'rotate-180': isOpen7, 'rotate-0': !isOpen7,}" src="../assets/aboutme/sun.png">
          </div>
          <div class="accordion-content" v-show="isOpen7">
            <div class="accordion-content-text">
              It is particularly important to know the <b>needs of its users</b> and to design a visual prototype depending on t
              he problem and solution concept.
            </div>
          </div>
        </div>
      </div>
      <div class="knowledge">
        <h2>Programs & Knowledge</h2>
        <img src="../assets/work/knowledge.png" style="width: 100%;">
      </div>
    </div>
      <br><br><br><br>
      <hr style="color: var(--focus-color);">
    <FooterImpressum/>
  </div>
</template>

<script>
// @ is an alias to /src
import FooterImpressum from '@/components/FooterImpressum.vue';
import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'workView',
  components: {
    NavigationBar,
    FooterImpressum
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  },
  data() {
    return {
      isActive: false,
      uid: this._uid,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false,
      isOpen6: false,
      isOpen7: false,
      selectedLabel: 'All',
      categories: [
        { id: 0, label: 'All', isActive: true },
        { id: 1, label: 'UX', isActive: false },
        { id: 2, label: 'Frontend Development', isActive: false },
        { id: 1, label: 'Concepting', isActive: false },
        { id: 2, label: 'Prototyping', isActive: false },
        { id: 1, label: 'Machine Learning', isActive: false },
        { id: 2, label: 'Exhibition Design', isActive: false }
      ],
      projects: [
        { id: 1, name: 'Zebra', description: 'A tool for creating audiodescription', route: 'zebra', image: 'work/zebra.jpg', labels: ['Concepting', 'UX', 'All'] },
        { id: 2, name: 'Plantanical', description: 'An app for remote plant monitoring', route: 'plantanical', image: 'work/plantanicalOverview.png', labels: ['UX', 'All'] },
        { id: 3, name: 'WiWa', description: 'Deer crossing warning system', route: 'wiwa', image: 'work/wiwaOverview.png', labels: ['UX', 'All'] },
        { id: 4, name: 'Data visualization', description: 'Data set about traffic accidents', route: 'datenvisualisierung', image: 'work/Mockup3.png', labels: ['Concepting', 'All'] },
        { id: 5, name: 'Remote Gardening', description: 'Modular remote plant watering system', route: 'remotegardening', image: 'work/rg1.png', labels: ['Prototyping', 'All'] },
        // { id: 6, name: 'User Journey Map', description: 'A user test based on a "Think Aloud"', route: 'userjourney', image: 'userjourneymapOverview.png', labels: ['Concepting', 'All'] },
        { id: 6, name: 'Garden Moisture', description: 'Modular remote plant watering system', route: 'gardenmoisture', image: 'work/gardenmoistureOverview.png', labels: ['Frontend Development', 'Prototyping', 'All'] },
        { id: 7, name: 'Malu', description: 'An assistant for demensia patients', route: 'malu', image: 'work/maluOverview.png', labels: ['Concepting', 'Machine Learning', 'All'] },
        { id: 8, name: 'Dive into the Neckar', description: 'An exhibition about river environments', route: 'neckar', image: 'work/neckarOverview.png', labels: ['Exhibition Design', 'All'] },
        { id: 9, name: 'Prison sensory room', description: 'Social innovation for Estonian prisons', route: 'prisonsensoryroom', image: 'work/prisonOverview.png', labels: ['Concepting', 'All'] },
        { id: 10, name: 'Fatigue tracker', description: 'Voice interface to prevent micro-sleeping', route: 'fatiguetracker', image: 'work/fatigueTracker.png', labels: ['Machine Learning', 'All'] },
        { id: 11, name: 'Dunlop Landingpage', description: 'Frontend development for Dunlop', route: 'dunlop', image: 'work/dunlopOverview.png', labels: ['Frontend Development', 'All'] },
        { id: 12, name: 'Deep Flow', description: 'A productivity home device', route: 'deepflow', image: 'work/deepflowOverview.png', labels: ['Prototyping', 'All'] },
        { id: 13, name: 'Protect me', description: 'A website about media literacy', route: 'protectmefromwhatiwant', image: 'work/protectmeHeader2.png', labels: ['UX', 'Concepting', 'Frontend Development', 'All'] }
      ],
      cate: [
        {
          id: 1,
          label: 'UX',
          projects: [
            { id: 1, name: 'Plantanical', description: 'An app for remote plant monitoring' },
            { id: 2, name: 'WiWa', description: 'Deer crossing warning system for safe driving' },
          ],
        },
        {
          id: 2,
          label: 'Frontend Development',
          projects: [
            { id: 3, name: 'Dunlop Landingpage', description: 'Frontend development for Dunlop' },
            { id: 4, name: 'Project 4', description: 'Description for Project 4.' },
          ],
        },
        {
          id: 3,
          label: 'Concepting',
          projects: [
            { id: 3, name: 'Data visualization', description: 'Data set about traffic accidents in Germany' },
            { id: 4, name: 'User Journey Map', description: 'A user test based on a "Think Aloud"' },
            { id: 4, name: 'Malu', description: 'An assistant for demensia patients' },
            { id: 4, name: 'Sensory room for prisons', description: 'Social service innovation for Estonian prisons' },
          ],
        },
        {
          id: 4,
          label: 'Prototyping',
          projects: [
            { id: 3, name: 'Remote Gardening', description: 'Modular remote plant watering system' },
            { id: 4, name: 'Garden Moisture', description: 'Modular remote plant watering system' },
            { id: 4, name: 'Deep Flow', description: 'A productivity home device' },
          ],
        },
        {
          id: 3,
          label: 'Machine learning',
          projects: [
            { id: 3, name: 'Malu', description: 'An assistant for demensia patients' },
            { id: 4, name: 'Fatigue tracker', description: 'Voice interface to prevent micro-sleeping' },
          ],
        },
        {
          id: 3,
          label: 'Exhibition design',
          projects: [
            { id: 3, name: 'Dive into the Neckar', description: 'An interactive exhibition about river environments' },
            { id: 4, name: 'Project 4', description: 'Description for Project 4.' },
          ],
        },
      ],
      selectedProject: !null,
    }
  },
  computed: {
    filteredProjects() {
      return this.projects.filter(project => project.labels.includes(this.selectedLabel));
    },
  },
  methods: {
    showAllProjects() {
      this.selectedLabel = 'All';
      this.isActive = !this.isActive;
    },
    showProjectsInCategory(categoryLabel) {
      this.selectedLabel = categoryLabel;
      this.categories.forEach((category) => {
        category.isActive = category.label === categoryLabel;
      });
    },
    toggleAccordion(elementId) {
      if (elementId === 1) {
        this.isOpen1 = !this.isOpen1;
        this.isOpen2 = false,
        this.isOpen3 = false,
        this.isOpen4 = false,
        this.isOpen5 = false,
        this.isOpen6 = false,
        this.isOpen7 = false
      } else if (elementId === 2) {
        this.isOpen2 = !this.isOpen2;
        this.isOpen1 = false,
        this.isOpen3 = false,
        this.isOpen4 = false,
        this.isOpen5 = false,
        this.isOpen6 = false,
        this.isOpen7 = false
      } else if (elementId === 3) {
        this.isOpen3 = !this.isOpen3;
        this.isOpen1 = false,
        this.isOpen2 = false,
        this.isOpen4 = false,
        this.isOpen5 = false,
        this.isOpen6 = false,
        this.isOpen7 = false
      } else if (elementId === 4) {
        this.isOpen4 = !this.isOpen4;
        this.isOpen1 = false,
        this.isOpen2 = false,
        this.isOpen3 = false,
        this.isOpen5 = false,
        this.isOpen6 = false,
        this.isOpen7 = false
      } else if (elementId === 5) {
        this.isOpen5 = !this.isOpen5;
        this.isOpen1 = false,
        this.isOpen2 = false,
        this.isOpen3 = false,
        this.isOpen4 = false,
        this.isOpen6 = false,
        this.isOpen7 = false
      } else if (elementId === 6) {
        this.isOpen6 = !this.isOpen6;
        this.isOpen1 = false,
        this.isOpen2 = false,
        this.isOpen3 = false,
        this.isOpen4 = false,
        this.isOpen5 = false,
        this.isOpen7 = false
      } else if (elementId === 7) {
        this.isOpen7 = !this.isOpen7;
        this.isOpen1 = false,
        this.isOpen3 = false,
        this.isOpen4 = false,
        this.isOpen5 = false,
        this.isOpen6 = false,
        this.isOpen2 = false
      }
    },
  },
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
  outline: none;
}
.body {
  text-align: center;
}
.work {
  overflow: hidden;
}
h2{
  font-family: "Space Grotesk";
  width: 300px;
  text-align: left;
  flex-wrap: nowrap;
  color: var(--text-color-black, #1C1C1C);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline 3px;
  text-underline-offset: 5px;
  margin-bottom: 80px;
}
h3 {
  font-family: "Space Grotesk";
  width: 300px;
  text-align: left;
  flex-wrap: nowrap;
  color: var(--text-color-black, #1C1C1C);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.el-row{
  gap: 40px;
}
hr {
  border-width: 2px;
  border-color: #8DDB4D;
}
.labels {
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  margin: 0px 80px;
}
.label {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 21.5px;
  border: 1px solid #000;
  cursor: pointer;
  white-space: nowrap;
}
.label-text {
  color: var(--text-color-black, #000);
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.active {
  border-radius: 21.5px;
  background: var(--focus-color, #8DDB4D);
  border: #8DDB4D 1px solid;
}
.overline {
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  color: #8DDB4D;
}
.first-column {
  display: flex;
  width: 698px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}
.headline-area {
  text-align: left;
  align-items: flex-start;
  gap: 100px;
  display: flex;
  padding: 0px 80px;
  align-items: flex-start;
  gap: 250px;
}
.row{
  padding-left: 80px;
  justify-content: flex-start;
}

.body{
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  min-height: 100vh;
  margin-top: 50px;
  padding-left: 5px;
  padding-right: 5px;
}
.image2{
  padding-bottom: 15px;
  justify-content: center;
  width: 100%;
}
.text{
  color: black;
  text-align: left;
  padding-bottom: 24px;
  padding-right: 80px;
  margin-left: 0px;
}
.zoom{
  transition: transform .5s; /* Animation */
}
.zoom:hover{
  transform: scale(0.9);
}
.underline{
  color: rgb(141, 141, 141);
  font-family: Avenir, sans-serif;
  font-size: 18px;
  height: 48px;
}
.accordion-list {
  display: flex;
  /* width: fit-content; */
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-inline-start: 80px; 
  margin-inline-end: 100px;
  margin-block-end: 200px;
}
.accordion-item {
  width: 100%;
}
.accordion-trigger{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-content {
  border-bottom: 2px solid #000;
  animation: fadeIn 2s;
}

.accordion-content-text {
  animation: fadeIn 1s;
  color: black;
  /* landingpage content */
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: revert;
  text-align: left;
  padding-block: 24px 24px;
  width: 50%;
}
.expertise-text-number {
  color: var(--focus-color, #292929);
  /* project page/headline content */
  font-family: "Space Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.expertise-text-description {
  cursor: pointer;
  color: var(--focus-color, #8DDB4D);
  /* h1 focus headline */
  font-family: "Space Grotesk";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  width: 600px;
}
.expertise-fold-icon {
  cursor: pointer;
  width: 5%;
}
.rotate-180 {
  cursor: pointer;
  transform: rotate(90deg);
  transform-origin: 50% 50%;
  transition: ease-in-out 500ms;
  height: 100%;
}
.rotate-0 {
  transition: ease-in-out 500ms;
}

.knowledge {
  margin: 80px;
}

@media screen and (max-width:1200px){
  .label-text {
    font-size: 16px;
  }
  .labels {
    display: flex;
    overflow-x: auto; /* Enable vertical scrolling */
    max-height: 300px; /* Adjust the maximum height based on your design */
    margin-inline: 80px;
    justify-content: center;
  }
  .label{
    padding: 4px 12px;
  }
}

@media screen and (max-width:1080px){
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 16px;
  }
  .underline {
    font-size: 14px;
    display: none;
  }
  .row{
    display: flex;
    flex-direction: row;
    flex: 50%;
    margin: 1px 0;
    justify-content: center;
    padding-right: 80px;
  }
  .column{
    width: 50%;
    /* height: 50%; */
    /* column-gap: 10px; */
    /* flex: 50%; */
    flex-basis: 50%; 
    /* margin-bottom: 50px; */
    margin-left: 0px;
    justify-content: left;
  }
  .el-row {
    gap: 20px;
  }
  .labels {
    overflow-x: auto; /* Enable vertical scrolling */
    margin-block-end: 32px;
    justify-content: flex-start;
    padding: 24px 24px 24px 0px;
  }
  .body {
    margin-top: 0px;
  }
  .text {
    padding-bottom: 8px;
  }
}
@media only screen and (max-width:900px){
  .expertise-text-description {
    width: 100%;
    font-size: 28px;
  }
  .accordion-trigger{
    gap: 80px;
  }
  .accordion-list {
    margin-inline-end: 80px;
  }
}
@media only screen and (max-width:700px){
  el-row{
    width: 100%;
    flex-direction: column-reverse;
    margin: 50px 0;
  }
  .el-row{
    max-width: fit-content;
  }
  el-col{
    padding-left: 30px;
    width: 30%;
    text-align: center;
  }
  .body{
    margin-right: 40px;
    justify-content: left;
  }
  .row{
    width: fit-content;
    flex-direction: column;
    margin: 1px 0;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .image2{
    width: 300px;
    padding-bottom: 0px;
  }
  .column p{
    font-size: 15px;
    flex-wrap: wrap;
    word-break: normal;
  }
  div{
    justify-content: left;
    margin-left: 0px;
  }
  .column{
    flex-basis: 100%;
    margin-bottom: 50px;
    /* margin-left: 25px; */
    justify-content: left;
    width: fit-content;
  }
  .text{
    text-align: center;
    padding-bottom: 10px;
    padding-right: 0px;
    /* padding-left: 30px; */
    /* padding-right: 80px; */
    color: black;
    width: 220px;
  }
  .zoom {
    width: fit-content;
  }
  p{
    width: 300px;
    text-align: left;
    flex-wrap: nowrap;
  }
  el-col{
    width: 100%;
  }
  .expertise-text-description {
    font-size: 24px;
  }
  .expertise-fold-icon {
    width: 30px;
    height: 30px;
  }
  .accordion-list {
    margin-inline-end: 80px;
  }
  .accordion-content-text {
    font-size: 16px;
    width: 100%;
  }
  .mobile {
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* margin-left: 80px; */
    margin-right: 80px;
  }
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeOut {
  100% { opacity: 1; }
  0% { opacity: 0; }
}

@media only screen and (max-width:550px){
  .labels{
    margin-inline: 40px;
  }
  .column {
    /* margin-left: 58px; */
    margin-bottom: 24px;
  }
  .image2 {
    width: 250px;
  }
  .knowledge {
    margin: 40px;
  }
  h2 {
    font-size: 26px;
    margin-bottom: 32px;
    text-decoration: underline 2px;
    text-underline-offset: 4px;
  }
  .accordion-list{
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
  .headline-area{
    padding: 0px 40px;
  }
}
::-webkit-scrollbar{
        height: 3px;
        width: 3px;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #8DDB4D;
        border-radius: 10px;
    }

</style>