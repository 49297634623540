<template>
  <nav fixed="top" :offset="120">
    <!-- <router-link to="/"> -->
      <!-- <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :class="{'green-fill': $route.path === '/'}" d="M19.0801 0.75V31.7726L4.17871 2.3949L3.5 1H3.4707H3.33008H3.02344H0.5L1.14941 1.95068L20.3379 39.7791L39.8525 0.75H19.0801Z" fill="black"/>
      </svg> -->
      <div><router-link to="/" class="capital">F</router-link></div>
    <!-- </router-link> -->
    <div class="nav-links">
      <ul>
        <li class="add-dot"><router-link to="/aboutme">About me</router-link></li>
        <li class="add-dot"><router-link to="/work">Work</router-link></li>
        <li class="add-dot"><router-link to="/contact">Contact</router-link></li>
      </ul>
    </div>
    <div class="capital" style="width:33px;">V</div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
nav{
  z-index: 300;
  position: sticky;
  top:0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px; 
}

.capital {
  font-family: "Chonburi";
  font-weight: 800;
  font-size: 48px;
  width: 60px;
  color:black;
  content: "• "; 
}

path:active, path:hover, path:focus, path:visited {
  fill: #8DDB4D;
  stroke: #8DDB4D;
}

.green-fill {
  fill: #8DDB4D;
  stroke: #8DDB4D;
}

nav img{
  background-color: transparent;
  width: 35px;
}

.nav-links{
  font-size: 16px;
  font-family: Avenir, sans-serif;
  font-weight: lighter;
  background-color: white;
  flex: 1;
  text-align: center;
  color: #8DDB4D;
}

.nav-links ul{
  padding: 0px;
}

nav a.router-link-exact-active::after {
  content: " •";
}

.nav-links ul li{
  font-weight: lighter;
  font-family: Avenir, sans-serif;
  background-color: white;
  display: inline-block;
  padding: 8px 40px;
  position: relative;
  color: #8DDB4D;
}

.nav-links ul li:hover{
  font-weight: bold;
  background-color: white;
  color: #8DDB4D;
  content: " •";
}

.nav-links ul li::after{
  background-color: white;
  content: "";
  /* width: 0%;
  height: 2px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 1.5s;
  color: #8DDB4D;*/
}

nav a.router-link-exact-active {
  content: "• "; /*don't miss the space*/
  font-weight: bold;
  color: #8DDB4D;
  visibility: visible;
}

/* .nav-links ul li:hover:after{
  background-color: white;
  color: #8DDB4D;
  width: 100%;
} */

a{
  font-weight: 500;
  color: black;
  background-color: white;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  text-align: start;
}

a:hover{
  color: #7ac43e;
}

@media only screen and (max-width:800px){
  /* a{
    width: 90px;
    text-align: start;
  } */
  .nav-links ul li{
    padding: 8px 20px;
  }
}

@media only screen and (max-width:700px){
  .nav-links ul li{
    font-weight: lighter;
    font-family: Avenir, sans-serif;
    background-color: white;
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
    color: rgb(59, 135, 115);
  }
}

@media only screen and (max-width:550px){
  nav{
    padding: 0px 40px;
  }
  a{
    font-size: 14px;
  }
  .capital {
    font-size: 36px;
    width: 50px;
  }
  .nav-links ul li{
    padding: 8px 10px;
  }
}
</style>
