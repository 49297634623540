<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Protect me from what I want</h2>
                <h3>A website about media literacy</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/protectmeProject/protectmeStart.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über13">&#10035; COURSE</span><br>
                    Lean <br> Design 
                </div>
                <div class="what">
                    <span class="Über13">&#10035; SEMESTER</span><br>
                    WS 2024 IoT7
                </div>
                <div class="what">
                    <span class="Über13">&#10035; DURATION</span><br>
                    3 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über13">&#10035; TOOLS</span><br>
                    Figma <br> React <br> CSS & HTML
                </div>
                <div class="what">
                    <span class="Über13">&#10035; TEAM</span><br>
                    Joschua Rothenbacher <br> Nyal Hettmer
                </div>
              </div>  
        </div>
        <br>
        <hr class="hr13">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline13"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Are you ready to take back control?</span></h5><br><br><br><br>
                <div class="left">
                  <p class="left">With our concept, we help you to become media literate and to rethink the use of technology. At a time when digital media permeate our everyday lives, the question of a conscious approach to technology is more pressing than ever. We help you to promote a sustainable awareness of media consumption and show you ways to create a healthy balance between the digital and analog worlds. Dealing with the topic of digital-analog balance and the findings from the user tests led to a deeper understanding of our own consumption. Understanding the mechanisms that lead to unwanted consumer behavior and recognizing our own behavioral patterns enabled us to develop targeted strategies to find a healthier balance between the digital and analog worlds.</p>
                </div>
                <img src="../assets/protectmeProject/protectMe.gif" class="plant" alt="" style="width: 30% !important; margin-left: 30px;">
                <a href="https://protectmefromwhatiwant.netlify.app" target="_blank"><h1 class="ani13">Visit the website</h1></a>
                <br><br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline13"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Interviews</span></h5><br><br><br><br>
                <p class="left">
                  During a conversation with a social worker from the media addiction counseling service Neues Land e.V., we first became aware of the concept of media literacy, which turned out to be a central component of our project. <br>
                  "Media addiction therapy teaches the reflective use of media. This is called media literacy. It's about taking a break after important activities and creating a space for reflection. “
                  - Daniel Rose, Neue Land e.V. 
                </p>
                <!-- <img src="../assets/plant3.png" class="plant" alt=""> -->
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline13"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Final Concept</span></h5><br><br><br><br>
                <p class="left">
                  The idea of combining the functionality of *OneSec* and *Focus Mode* with targeted information processing is an effective solution for developing an awareness of one's own media consumption. OneSec, as an important app, and Focus Mode as a feature ensure rapid success in terms of reducing unwanted media use. Supplementary and stimulating information on media literacy can be used to effectively establish a healthy digital-analog balance. Such a collaboration between the use of *OneSec*, the *Focus Mode* and careful information processing can achieve long-term success and represent real added value for users by not only promoting immediate behavioral changes, but also deepening understanding and reflection on their own media consumption.
                </p>
                <img src="../assets/protectmeProject/Lösungsraum.png" class="plant" alt=""><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline13"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Protect me from what I want</span></h5><br><br><br><br>
                <p class="left">
                  In the feedback discussion with our supervisors, reference was made to the art project “Protect me from what I want” by Jenny Holzer. She used the phrase in the mid-1980s to stimulate discussions about human nature and desire. It was precisely this subtle but apt confrontation with the viewer that inspired us to design the website under this slogan. The phrase reflects the central idea of protecting users from the potentially harmful effects of their own consumer behavior by encouraging a more conscious use of digital media.                </p>
                <br><br><br>
            </div>
        </div>
        <br>
        <router-link to="/zebra"><h1 class="ani13">Go back to first project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.headline13{
    color: #9A5DFF;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani13 {
    color: #9A5DFF;
}
nobr {
    color: #9A5DFF;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #9A5DFF;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold13{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #9A5DFF;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #9A5DFF;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline13{
  color: #9A5DFF;
}
.hr13{
    border-width: 2.5px;
    border-color: #9A5DFF;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #9A5DFF; 
  border: 5px solid #9A5DFF;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über13{
    color: #9A5DFF;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 80%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #9A5DFF;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani13{
  font-size: 50px;
  color: #9A5DFF;
}
.ani13:hover{
  color: rgb(0, 0, 0);
}
.ani13::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #9A5DFF;
}
.ani13:hover:after{
  color: #9A5DFF;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani13{
    font-size: 25px;
  }
  .ani13:hover:after{
    color: #9A5DFF;
    width: 30%;
  }
  .Über13{
    word-wrap: none;
  }
  .headline13{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold13{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr13 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr13 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline13 {
    font-size: 24px;
  }
}
</style>