<template>
  <div class="contact">
    <NavigationBar />
    <div class="bg-image3 fade-in">
        <div class="headline-area">
          <div class="first-column">
            <div class="overline">Start our journey now!</div>
            <h1 style="color: #D8CBC3">Let’s create projects with passion together!</h1>
            <a href="mailto:fabiennevatter@web.de" class="contact-button">Let's connect</a>
            <div class="shoutout">Image by <a href="https://lunakloess.de">Luna Kloess</a></div>
          </div>
        </div>
    </div>
    <FooterImpressum />
  </div>
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import FooterImpressum from '@/components/FooterImpressum.vue'

export default {
  name: 'contactView',
  components: {
    NavigationBar,
    FooterImpressum
  }
}
</script>

<style scoped>
*{
  --color-focus: #8DDB4D;
  --color-primary: #1C1C1C;
  --color-light: #D8CBC3;
  font-family: Avenir;
}
.bg-image3 {
  padding: 100px 0px 379px 0px;
  height: 95vh;
  width: 100%;
  background-image: url("../assets/contact/contactImage2.png");
  background-size: cover;
  background-position: center;
}
.contact-button {
  display: flex;
  width: 158px;
  height: 49px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 2px solid var(--color-light);
  color: var(--color-light);
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  margin-top: -40px;
}
.overline {
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  color: var(--color-focus);
}
.first-column {
  display: flex;
  width: 698px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}
.headline-area {
  text-align: left;
  align-items: flex-start;
  gap: 100px;
  display: flex;
  padding: 0px 80px;
  align-items: flex-start;
  gap: 250px;
}
.shoutout {
  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-light);
  margin-bottom: 40px;
  margin-right: 80px;
  position: absolute;
  bottom: 0;
  right: 0;
}
a {
  text-decoration: none;
}
.shoutout a {
  text-decoration: underline;
  color: var(--color-light);
}

/* old from here on */

@media only screen and (max-width:700px){
  img{
    padding-top: 0px;
    width: 80%;
  }
  h2{
    text-align: center;
    font-size: 20px;
    padding-left: 38px;
  }
  h1{
    padding-left: 0px;
    font-size: 40px; 
  }
  a{
    padding-right: 20px;
  }
}
@media only screen and (max-width:550px){
  .headline-area{
    padding: 0px 40px;
  }
  .shoutout {
    margin-right: 40px;
  }
}
</style>