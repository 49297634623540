<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Dive into the Neckar</h2>
                <h3>An interactive exhibition project about the native habitats inside and next to the river Neckar in Baden-Württemberg</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/neckarProject/neckarTop.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über8">&#10035; COURSE</span><br>
                    Interaktive <br> Kommunikationssysteme <br> Ausstellungsgestaltung 
                </div>
                <div class="what">
                    <span class="Über8">&#10035; SEMESTER</span><br>
                    SS 2022 IoT4
                </div>
                <div class="what">
                    <span class="Über8">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über8">&#10035; TOOLS</span><br>
                    Figma <br> ProtoPie <br> Arduin0
                </div>
                <div class="what">
                    <span class="Über8">&#10035; TEAM</span><br>
                    Malin Jerg <br> Julia Motz <br> Maurice Vater
                </div>
              </div>  
        </div>
        <br>
        <hr class="hr8">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline8"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <div class="left">
                  <h4 class="einleitung">As part of a cooperation with the Natural History Museum Stuttgart and the University of Education Schwäbisch Gmünd, the conception and implementation of a prototypical, didactic and media installation in the Natural History Museum Stuttgart was realized. The digital exhibits are intended to enable visitors to experience data, information and facts in an understandable way, present them in a meaningful context and also explain the topic in a vivid, lively and creative way. </h4>
                </div>
                <p class="left">
                  The topic of our group was native habitats in Baden-Württemberg. However, we narrowed the topic down even further and focused on the Neckar, which is characteristic of many habitats, animals and human intervention in nature in Baden-Württemberg. The Neckar makes it possible to get to know a wide variety of topics and to immerse oneself in the habitats in a haptic, visual and auditory way.</p>
                <br>
                <div style="padding:75% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/988487566?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Der Neckar"></iframe></div>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline8"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Concept</span></h5><br><br><br><br>
                <p class="left">
                  Finally, we liked best the approach of the combination of relief, next to it an ipad on a platform and a wall projection. The course of the Neckar river is projected onto the relief of Baden-Württemberg. With the help of a rotary control attached to the relief, users can choose between the categories of landscape, wildlife and people, which are then displayed on the projection and thus on the course of the river. For each category there are three theme points that can be selected on the relief. These are indicated by pulsating dots. Once a topic has been selected, users can read more information about it on the information level of the Ipad. A video on the respective topic is played on the wall projection. This allows visitors to immerse themselves in the habitat and experience the tapped locations on the Neckar and topics in a realistic way. The graphic shows our interaction points and thus our final concept. It shows the connection and interaction of all three elements and the technical process of our exhibit.                </p>
                  <img src="../assets/neckarProject/neckarAufbau.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline8"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">The relief</span></h5><br><br><br><br>
                <p class="left">
                  The main element of our exhibit is the relief, on which a projection is projected from above. The relief consists of 15 layers of 1 cm high kappa cardboard. The 15 layers were laser-cut and then glued together to fit. As our exhibit was designed as a single-use piece, we added a cut-out in the bottom right-hand corner. Through the recess, the visitors themselves become the center of the exhibit and become part of Baden-Württemberg. It also makes it easier for visitors to get to all the topics. In order to still be able to recognize Baden-Württemberg and not tear the relief out of its context, we have stuck the complete outline of Baden-Württemberg on the floor as an outline. Visitors can therefore step into Baden-Württemberg and become a part of it.                </p>
                  <img src="../assets/neckarProject/neckarRender.png" class="plant" alt="">
                  <img src="../assets/neckarProject/neckarBoden.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline8"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Technical Structure</span></h5><br><br><br><br>
                <p class="left">
                  The theme dots on the map change depending on the cateogy selection. We therefore had to move away from static, always activated buttons and find a solution for changing the points and thus the buttons each time. The solution is a capacitive button whose input is only queried in the appropriate category status. As we have 3 topic points in each of three categories, we needed 9 capacitive buttons on the relief. The projection then shows the user where the capacitive buttons are located for each category and only these are activated in the respective state. A bare conductive board gave 
us the opportunity to program 9 capacitive buttons. The picture below shows the position of all the theme points and the wiring on the underside of the relief.
                </p>
                <img src="../assets/neckarProject/neckarT.png" class="plant" alt=""><br><br>
                <p class="left">
                The Bareconductive Arduino board has 12 capacitive inputs, which we used as capacitive buttons for our 9 topics. We 3D-printed the rotary control ourselves, cut it off and painted it white. To display the current category, we incorporated an elevation as a pointer, which is additionally
                which is additionally highlighted in black by the projection. The functional interior consists of a potentiometer that is bordered on two sides.
                is limited on two sides. Initially, we tried using reed contacts, which were installed on the upper side of the rotary control and had a magnet as a selection tool on the lower side. However, this did not work as well as we had imagined. In the end, our technical setup consisted of a bare conductive board with 9 capacitive buttons and a rotary control with a potentiometer. 
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline8"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">The code</span></h5><br><br><br><br>
                <p class="left">
                The potentiometer was divided into three ranges. Within these three areas, we have selected a sensor value range of 80 digits in which the categories are selected. We have also aligned this with the positions on the relief where the text labels are attached. These three areas are stored in three states. The Arduino code is structured in such a way that the loop function repeatedly queries which sensor value range is currently being turned and then the respective state is saved. The “checkState()” function then queries whether the currentState has changed from the lastState. Only then is a change to the projection output. This is done with keystrokes that trigger the Figma prototype. For example, “Keyboard.write(‘l’)” triggers the projection of the “Landscape” category. The state is then checked again in the checkTouch() function and the three capacitive inputs and thus the three matching theme points are activated within the state. If one of these inputs is touched, a further keystroke triggers the corresponding projection on the wall and Re-lief and the corresponding frame of the iPad application. In addition, a counter counts how often the point is touched in succession. If it is pressed twice, the projection jumps back to the respective category. You can therefore navigate back to the category via the relief in the topic point itself by pressing the same point again. This is indicated visually by an “X” on the dot.
                </p>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline8"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Final exhibition</span></h5><br><br><br><br>
                <img src="../assets/neckarProject/neckarReiher.jpg" class="plant" alt="">
                <img src="../assets/neckarProject/neckar2.png" class="plant" alt="">
                <br><br><br>
            </div>
        </div>
        <br>
        <router-link to="/prisonsensoryroom"><h1 class="ani8">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.headline8{
    color: #0500FF;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani8 {
    color: #0500FF;
}
nobr {
    color: #0500FF;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #0500FF;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold8{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #0500FF;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #0500FF;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline8{
  color: #0500FF;
}
.hr8{
    border-width: 2.5px;
    border-color: #0500FF;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #0500FF; 
  border: 5px solid #0500FF;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über8{
    color: #0500FF;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 50%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #0500FF;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani8{
  font-size: 50px;
  color: #0500FF;
}
.ani8:hover{
  color: rgb(0, 0, 0);
}
.ani8::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #0500FF;
}
.ani8:hover:after{
  color: #0500FF;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani8{
    font-size: 25px;
  }
  .ani8:hover:after{
    color: #0500FF;
    width: 30%;
  }
  .Über8{
    word-wrap: none;
  }
  .headline8{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold8{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr8 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr8 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline8 {
    font-size: 24px;
  }
}
</style>