<template> 
<!-- 7 -->
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Malu</h2>
                <h3>A wearable assistant including a speaker and camera, to help dementia patients, living alone at home, manage their daily live.</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/maluProject/MaluRender.png" alt="">
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über7">&#10035; COURSE</span><br>
                    Invention <br> Design 
                </div>
                <div class="what">
                    <span class="Über7">&#10035; SEMESTER</span><br>
                    SS 2021 IoT2
                </div>
                <div class="what">
                    <span class="Über7">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über7">&#10035; TOOLS</span><br>
                    Figma <br> Blender <br> Teachable Machine
                </div>
                <div class="what">
                    <span class="Über7">&#10035; TEAM</span><br>
                    Philipp Däschle
                </div>
              </div>  
        </div>
        <br>
        <hr class="hr7">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <p class="left">
                  1.6 million people in germany are affected by dementia
                  that means 2,2 students will be affected by dementia later on with a rising trend.
                  People that are dealing with dementia are loosing their independency and with that their quality of life is drastically decreasing. Espacially when they live alone and need to rely on a caretaker or relative to deal with little obstacle throughout the day. 
                  We want to givem them back some of their independence and increas their quality of life. But we don’t substitute a human or relative with our product, we empower the patient through small hints and reminder so they get back or remain their independency
                  People diagnosed with dementia need to relay very soon on a relative or caretaker to support them in daily activities and give them the needed help in cases of remembering and managing daily structures
                  When living at home with a relative, the relative becomes the help throughout the day. The relative will remind the patient of appointments, or correct him if something is missing.
                  But when there is no relative at the side of the patient, it gets complicated. No one that corrects or reminds. But thats where Malu comes into play. 
                  We remind the patient of important things, hint at objects that are missing and much more. All of that while not replacing a human being, but more like empowering the user to accomplish their goals by themselfs. 
                </p>
                <br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Technology</span></h5><br><br><br><br>
                <p class="left">
                  AI is a steadily growing, but not yet fully implemented tech solution for many problems. 
                  One Part of AI we saw great potential in, is the object detection. With that you could store visual data and process it for a meaningful activity or topic
                  With the object detection we can store (visual) data, for example what object is identified and where is it in forms of groups, rooms or structured data bases. With these information we can compare different images and detect which obejct is detected or seen.
                  You could say this data is like a memory, stored in a artificial brain and we can use it to process it for a meaningful activity or challenge(problem).     
                </p>
                <br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Dementia</span></h5><br><br><br><br>
                <p class="left">
                  Statistically, 2.2 people in 20 people will be diagnosed with dementia later in life.
                  Not only that, but as the quality of life increases in the next decades, so does dementia. so the number is likely to rise to 2.8 million. 
                  dementia is an illnes, where the brain cells cannnot communicate with eatch other. because of that the brain functionality decreases and during the illness the cells start to die out. 
                  Because of that, dementia patients start forgetting things, getting things mixed up, they are easily angered and are emotional. 
                  Of course one of the biggest pain points of dealing with dementia is forgetting and mixing up things. It lowers your self esteem when you don’t know where you put anything. It shatters your self image if you can’t even cook dinner or breakfast for yourself. 
                  And that’s where we want to support. Supporting with the little things that have so much impact on your independency, self-image and self esteem. 
                  If  a patient lives with a relative, they must rely on them supporting the patient with alle the little things. reminding them of appointments, telling them that they forgot an ingredient or where they put the keys.
                  And we want to help those who live alone. But we don’t want to substitute a human, we want to empower the user with our product, so he gains more independency and increase their self esteem.
                </p>
                <br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Innovation</span></h5><br><br><br><br>
                <p class="left">
                  Currently dementia patients, that are living on their own have to rely on caretakes to come over and  support them  in their daily live. The caretakers are not able to be there all the time. They also have the opportunity to visit group sessions, but these sessions are only up to 3 hours a day. The rest the day, they are on their own and have to either call for a caretaker or have to deal with their struggles alone. 
                  That’s where innovative technology can assist constantly throughout the day and help when no ones around.  
                  Alexa is an example for an voice interaction/assistant, but alexa can only work on active commands. where as Malu can understand what the user is trying to do and what he is missing, without him even knowing what he is missing. Because Dementia patients are limited in their comprehension and dont even notice if something isnt in the usual context. 
                </p>
                <br>
                <img src="../assets/maluProject/maluTLDR.jpg" class="plant" alt="">
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Concept</span></h5><br><br><br><br>
                <p class="left">
                  And it is precisely in these situations that our assistant comes to the aid of those affected and supports them. The Malu concept works as follows: Ideally, those affected should always carry Malu with them and attach it to their clothing so that the built-in camera has a clear view of what the person is doing.</p>
                <img src="../assets/maluProject/maluSketch.png" class="plant" alt="">
                <p class="left">
                  The integrated camera continuously feeds an artificial intelligence with information about what is currently happening. This information is processed by the AI and, with the help of object detection, determines which objects the person concerned would like to do something with. For example, the AI recognizes that the person concerned wants to make pancakes and checks whether everything needed is available. If this is not the case, Malu waits a moment and then informs the person that they should get a certain ingredient. This request is always formulated clearly and directly, as such formulations are best suited when dealing with dementia patients. In addition to this passive support, the person affected can also actively ask for help. This means that the user always has the option of actively asking where the pan is or where the flour is, for example. We have chosen precisely this scenario because we want to provide active support for small tasks that would otherwise be carried out by the family or carers. However, Malu's concept can also be applied to many other situations. For example, Malu can help with taking medicine, shopping and much more.
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Functionality</span></h5><br><br><br><br>
                <p class="left">
                  What are parts of Malu? 
                  The interaction between the user and the warable device on the shirt by voice and speech. 
                  Malu is working with room and object detection. We have a database structure where configuration will be there first thing to start with when malu arrives at the patients home. Theres a step-by-step introduction made in a very visual and clear way to enable the dementia person to install malu by themself. 
                  One part of the installation would be the calibration for all the data that should be available. for that the user puts on malu and walks through the whole apartement or house and “shows” malu the things that are important and stationary at some place like for example the pans or the hair dryer in the bathroom. 
                  everything else that could be put somewhere at a wrong or inconviniente place, can just be recognized by malu if shes worn in the situation when it is put there. beause then malu will safe the location of the object inside of groups and also rooms. so malu would know that the user put the hair dryer in the bedroom inside of the cupboard instead of the bathroom.  
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Interaction with Malu</span></h5><br><br><br><br>
                <p class="left">
                  But how exactly does the interaction looks like?
                  If the user is searching or missing something during his process of for example preparing breakfast without even noticing it by himself, Malu recognizes what the goal of the user is and will give hints/reminders. These hints are designed to not trigger or upset the user, which could happen pretty easily if they are actively reminding them that they forgot something. So we refrain from phrases like, you forgot the milk.
                  Through research and feedback from proffesionalls we learned, that determined and clear instructions on what to do next in order to achive the goal, are the most helpful and respecful way for interaction. This is what we call the passive support of Malu.
                  On the other hand there is also the active support, where the user can actively ask Malu “where is the pan” or “how to do Pasta” and Malu will give clear instructions on how to progress further.        
                  Despite that, malu continues to gather /collect data everytime it is put on. so by that malu wil learn and grow with the patient everytime the user is doing something at home. 
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Technical integration</span></h5><br><br><br><br>
                <p class="left">
                  We use big data technology to enable Malu to recognize objects.
                  You coud say that Malu builds herself a virtual brain where she stores all the important data. Her “Brain” is constantly getting updated with new data in order to further provide detailed help. 
                  She collects the data through image recognation, which she is using the build camera for
                  She talks to he patient via her speakers. 
                  The Informations are stored in a three layer sructure:  
                  Room: Malu recognizes in which room the user currently is in (kitchen, shower...)
                  Place: Furthermore Malu stores the information of where inside of the room the User is (ex. Fridge, dining table, drawer) 
                  Object: And finaly Malu is recognizing what the user is doing and what he is using  (ingredients, coffee taps, ex...
                  Privacy problem: we thought about it! Malu is censoring all other people, except for the user. Furthermore we want the data to be encrypted and stored locally. On top, we want to be transparent with the data we use, by telling the user why we need certain data (ex. We need the camera in order to know what you are struggeling with) and we work/proceed with the informations.
                  machine learning : the use and development of computer systems that are able to learn and adapt without following explicit instructions, by using algorithms and statistical models to analyse and draw inferences from patterns in data.
                </p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Prototyp Testing</span></h5><br><br><br><br>
                <p class="left">
                  What always plays a major role in user-centered design is validating and testing the concept. However, this was not so easy for us to manage, as we could not actively approach those affected without making them feel bad. So we had to come up with something else. We therefore designed the user tests by trying to simulate the situation of a dementia patient as closely as possible. We did this by choosing a few fellow students and placing them individually in a kitchen that was unfamiliar to them. Because they were not in their own four walls, they didn't know where certain things were right away. We then set them the task of cooking something very simple. Many of them forgot ingredients that they actually needed for the recipe. These missing ingredients were pointed out to them so that they could complete their task. During these tests, we received positive feedback and were able to validate our assumptions in a modified form.</p>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline7"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Malu in Action</span></h5><br><br><br><br>
                  <iframe width="100%" height="500" src="https://www.youtube.com/embed/vVtEqCv9Bos" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br><br><br>
            </div>
        </div>
        <br>
        <router-link to="/neckar"><h1 class="ani7">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.headline7{
    color: #5ca7b1;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani7 {
    color: #5ca7b1;
}
nobr {
    color: #5ca7b1;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #5ca7b1;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold7{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #5ca7b1;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #5ca7b1;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline7{
  color: #5ca7b1;
}
.hr7{
    border-width: 2.5px;
    border-color: #5ca7b1;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #5ca7b1; 
  border: 5px solid #5ca7b1;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über7{
    color: #5ca7b1;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 60%;
    padding-left: 100px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #5ca7b1;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani7{
  font-size: 50px;
  color: #5ca7b1;
}
.ani7:hover{
  color: rgb(0, 0, 0);
}
.ani7::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #5ca7b1;
}
.ani7:hover:after{
  color: #5ca7b1;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani7{
    font-size: 25px;
  }
  .ani7:hover:after{
    color: #5ca7b1;
    width: 30%;
  }
  .Über7{
    word-wrap: none;
  }
  .headline7{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold7{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr7 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
  iframe{
    height: 300px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr7 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline7 {
    font-size: 24px;
  }
  iframe{
    height: 300px;
  }
}
</style>