<template>
    <div class="home">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">Deep Flow</h2>
                <h3>A Focus-Enhancing Study Companion</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/deepflowProject/deepflowHeader.png" alt="">
                <!-- <div class="color-box"></div> -->
            </div>
        </div>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über12">&#10035; COURSE</span><br>
                    Tangible <br> Interaction Design
                </div>
                <div class="what">
                    <span class="Über12">&#10035; SEMESTER</span><br>
                    SS 2023 IoT6
                </div>
                <div class="what">
                    <span class="Über12">&#10035; DURATION</span><br>
                    2 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über12">&#10035; TOOLS</span><br>
                    Arduino <br> Fusion 360
                </div>
                <div class="what">
                    <span class="Über12">&#10035; TEAM</span><br>
                    Daphné Osterrieth <br> Hailey Bergmann
                </div>
              </div>  
            </div>
        <br>
        <hr class="hr12">
        <br><br><br><br>
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline12"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Project Content</span></h5><br><br><br><br>
                <p class="left">
                  Deep Flow, inspired by the pursuit of academic excellence, is a smart device tailored to assist university students in optimizing their study sessions. Utilizing the Pomodoro technique, a variety of focus sounds, and ambient lighting, this innovative creation delivers an immersive environment designed to foster deep work and heightened productivity.
                  Transcending mere functionality, Deep Flow offers a cognitive and emotional odyssey that encourages improved concentration and mental clarity, empowering students to take control of their academic success and personal growth.
                  With a variety of focus sounds and ambient lighting options, our device creates the ideal environment for deep work and productivity.
                  Designing for a small apartment in Tallsinki in 2123 was a challenging task, but it also presented us with many opportunities to innovate and create something truly meaningful. When we first started brainstorming ideas, we wanted to make sure that our design had multiple functions and was efficient in its use of space. However, as we progressed, we realised that we needed to evaluate our ideas and find a more specific focus point.
                  </p>
                <br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline12"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Combining skills</span></h5><br><br><br><br>
                <p class="left">
                  The process of creating our device not only resulted in a functional and effective end product, but it also provided us with an opportunity to acquire new hard skills and develop our soft skills.
                  We had to learn how to laser cut and engrave, bend plexi with a heat gun, and use Fusion 360 to create a 3D model of our design. We also had to become proficient in Arduino programming, working with IR sensors, connecting Processing via serial monitor, and incorporating the Processing sound library. Soldering and working with NeoPixel LED and Touchslider DIY were other skills we picked up along the way.
                </p>
                <img src="../assets/deepflowProject/deepFlowSpray.png" class="plant" alt="">
                <img src="../assets/deepflowProject/deepflowWood.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline12"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Integration of Technologies</span></h5><br><br><br><br>
                <p class="left">
                  From the very start, we envisioned a touchslider as an integral part of our product for controlling sound volume. We knew that accessibility was paramount and that we needed to create a setting for sound volume that seamlessly integrated with our product. To achieve this, we opted for a more modern and minimalistic approach, steering clear of traditional hardware components such as physical sliders or buttons. Our solution was to implement a touchslider that was visually concealed beneath a thin layer of wood, with only a small light indicating its presence. To ensure that everyone could use it, we added plus and minus buttons for clarity.
                  We devised our own form of slider, utilising the capacity change method and dividing the slider into five touch areas. Each area was connected to two LEDs on the LED stripe and one volume setting. Moving across the five areas would result in a corresponding increase or decrease in volume, with the LED stripe visually indicating the level of volume on the device. The benefits of our approach included touch activation through thin layers of various materials, unrestricted use of materials such as aluminium cans or kitchen foil, and easy handling with the esp32. No resistors, capacitors, or other components were required.
                  However, there were some limitations to this approach. It required extensive research and testing, as there were no good libraries or documentation available for what we needed. It was not possible to create a single interrelated slider bar, so we had to divide it into the five areas. Additionally, the overlaying material could not be too large.
                  We also incorporated infrared sensors to indicate the ball's position on the activation platform and which sound option the user selected. We marked the ball with a black spot and adjusted the value range to trigger the sound according to the IR sensor's output. For activating the break or work mode, we used a different value range to differentiate between the two functions.
                  The benefits of using IR sensors were numerous, including different response options due to the value range setting, and easy testing. However, there were also limitations. It required a complex circuit, was easy to damage due to incorrect wiring, and the sensors were quite sensitive. A lot of fine-tuning was necessary, as the final value settings differed from those in the testing environment.
                </p>
                <img src="../assets/deepflowProject/deepflowTechnik.png" class="plant" alt="">
                <img src="../assets/deepflowProject/deepFlowInside.png" class="plant" alt="">
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
              <h5 class="w3-left headline12"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Final Product</span></h5><br><br><br><br>
                <img src="../assets/deepflowProject/deepflow1.jpg" class="plant" alt="">
                <img src="../assets/deepflowProject/deepflow2.jpg" class="plant" alt="">
                <img src="../assets/deepflowProject/deepflow3.jpg" class="plant" alt="">
                <br><br><br>
            </div>
        </div>
        <br>
        <router-link to="/protectmefromwhatiwant"><h1 class="ani12">Next project</h1></router-link>
        <br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'plantanicalView',
  components: {
    NavigationBar
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.headline12{
    color: #D16A5C;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani12 {
    color: #D16A5C;
}
nobr {
    color: #D16A5C;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold12{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #D16A5C;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #D16A5C;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline12{
  color: #D16A5C;
}
.hr12{
    border-width: 2.5px;
    border-color: #D16A5C;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #D16A5C;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #D16A5C; 
  border: 5px solid #D16A5C;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über12{
    color: #D16A5C;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 100%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #D16A5C;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani12{
  font-size: 50px;
  color: #D16A5C;
}
.ani12:hover{
  color: rgb(0, 0, 0);
}
.ani12::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #D16A5C;
}
.ani12:hover:after{
  color: #D16A5C;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani12{
    font-size: 25px;
  }
  .ani12:hover:after{
    color: #D16A5C;
    width: 30%;
  }
  .Über12{
    word-wrap: none;
  }
  .headline12{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold12{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr12 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr12 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline12 {
    font-size: 24px;
  }
}
</style>