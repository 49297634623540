<template>
    <div class="container">
        <NavigationBar />
        <div class="row">
            <div class="col-one">
                <h2 class="header">WiWa</h2>
                <h3>WiWa is a sensor system attached to the delineator, which warns the motorist locally at dangerous spots. It is designed to ensure the safety of the motorist and animal.</h3>
            </div>
            <div class="col-two">
                <img class="header-image" src="../assets/wiwaProject/transparent.png" alt="">
            </div>
        </div><br><br>
        <div class="center">
            <div class="row align">
                <div class="what">
                    <span class="Über3">&#10035; COURSE</span><br>
                    Prototyping & <br> Redesign
                </div>
                <div class="what">
                    <span class="Über3">&#10035; SEMESTER</span><br>
                    WS 2021/22 IoT3
                </div>
                <div class="what">
                    <span class="Über3">&#10035; DURATION</span><br>
                    4 1/2 Monate
                </div>
                <div class="what">
                    <span class="Über3">&#10035; TOOLS</span><br>
                    Figma <br> Cinema 4D <br> RaspberryPi
                </div>
                <div class="what">
                    <span class="Über3">&#10035; TEAM</span><br>
                    Joschua Rothenbacher 
                </div>
              </div>  
        </div>
        <hr class="hr3">
        <br><br><br><br>
    </div>

    <div class="home">
        <div class="w3-container" id="about">
            <div class="w3-content" style="max-width:900px">
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/714907358?h=448285d4b5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="MainCompAusstellung"></iframe></div><br><br><br>
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">User Research</span></h5><br><br><br><br>
                <p class="left">
                  The topic cluster around wildlife crossing and animal behavior was new territory for both of us. This is why we first talked to foresters and searched for comparable wildlife warning products. 
                Our conversations with the forester Joachim Stier and hunting leaseholder Timo Schmitt provided us with numerous insights and helped us validate various concepts. 
                Also the permission to install a wildlife camera in his leased area helped us to understand how often animals pass at endangered spots.
                </p>
                <img src="../assets/wiwaProject/tier.jpg" class="plant" alt=""><br><br>
                <br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Product approaches</span></h5><br><br><br><br>
                <div class="einleitung">"Most of the products on the market and on the streets so far are successful for the first few weeks but then quickly lose their effectiveness." <br> - Joachim Stier, Forest Area Manager Schwäbisch Gmünd</div>
                <p class="left">
                  Each existing solution has different weaknesses. The most common method used to avoid deer crossing are blue reflectors, 
                which are mounted on the delineator. The resulting blue light is supposed to alert the animals and make them stop. As we found out in discussions with our experts 
                the animals get used to the light after only 3 to 4 months. That means the system works comparatively well at first but then loses its effectiveness very quickly. 
                Other products, which try to warn animals via high-frequency sounds have the same disadvantage. It is clear that if you want to consistently 
                and sustainably prevent accidents with wild animals, the driver must be warned. Therefore, our new user group are car drivers.
                </p>
                <br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Implementation</span></h5><br><br><br><br>
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/714904369?h=0f19bfc680&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="prozessSequenz_1"></iframe></div>
                <br><div style="color: #4a8152">Some impressions from our process</div>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Concepts</span></h5><br><br><br><br>
                <div class="left">During the implementation we went through many different iteration phases, most of them looked like this:
                <br><br>
                <nobr class="bold3"> 01 </nobr> Create implementation concept <br>
                <nobr class="bold3"> 02 </nobr> Prototype <br>
                <nobr class="bold3"> 03 </nobr> Test <br>
                <nobr class="bold3"> 04 </nobr> Validate <br>
                <nobr class="bold3"> 05 </nobr> Generate improved concept <br><br>
                First, we wanted to detect the change through a light barrier at the roadside. We built a prototype using a Raspberry Pi which sent a message as soon as the light barrier is interrupted. In the next iteration, we added an infrared camera to our prototype to validate the break of the light barrier. Now we could detect the change and evaluate it in an external service.
                After a discussion with an expert about a possible power supply using a solar panel, it became clear that our current prototype had a too high power demand. Unfortunately, this concept also had to be revised.
                In the final prototype, change detection works exclusively via the infrared camera. 
                <br><br>
                </div>
                <br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Warning on site</span></h5><br><br><br><br>
                <div class="left">Now that an image has been stored in the database, the next step is to warn the user. This is done in three ways. The warning on site is especially important for the user group that doesn't have a smartphone or the app. The location-based warning is divided into two parts, a traffic sign and a delineator module.</div><br>
                <img src="../assets/wiwaProject/render1.jpg" class="picture plant" alt=""><br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">1. Traffic sign</span></h5><br><br><br><br>
                <div class="left">
                    The user should be made aware of the facts and the hazardous situation for the first time before driving into the potential danger zone. This is done with an additional note on the conventional wildlife sign. For this, we considered several variants and finally decided on a prismatic turner and prototyped it. However, we quickly realized that a prismatic turner would be too large and the problem could also be solved in a more material- and cost-saving way. In addition, it had to fit the product semantics of the sign hanging above and should be adapted to the road traffic regulations.
                    So we came up with a second variant. The base is a rectangular sign with a cutout containing a coated paper roll that is rotated by two rods connected by toothed belts. On the paper roll are the three states "Low Danger", "Increased Danger" and "Acute Danger". When the corresponding danger situation occurs the respective status can then be approached and thus appears in the recessed area.
                </div>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">2. Delineators</span></h5><br><br><br><br>
                <div class="left">
                    The part that should warn the driver in the danger zone itself is
                    located on the delineator. It can be equipped with a modular system, which you simply screw to the already existing delineator. A second option would have been an intelligent road post. In this case, the post would be completely redesigned and the technology would be installed on the inside. The solar panel would be mounted on top of a beveled surface, leaving a recess for the camera module. We decided against this concept because it would have involved a lot of extra work, high costs and would have been difficult to implement in reality. Just disassembling all the posts would have been a major challenge.
                    So we decided to go with the modular system. For this, we printed a 3D housing. The characteristic feature is the shape because we specially adapted the design to the shapes of the street post. The housing is designed to be as space-saving as possible, leaving just enough room for the RaspberryPi, the GSM header and the infrared camera module. The exteriors are also designed to position the camera at a suitable angle and to allow a solar panel to be easily installed on the top. The beveled surface allows a larger panel. This makes our delineator self-sufficient, modular, easy to implement and maintenance-optimized. We have tried to test our delineator in reality but we didn't get a test track in this short time. The road maintenance department Schwäbisch Gmünd, the police and also the Federal Ministry of Transport couldn't support us.
                </div><br>
                <img src="../assets/wiwaProject/leitpfosten.png" class="picture plant" alt=""><br>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">App</span></h5>
                <br><br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">1. Main interactions</span></h5><br><br><br><br>
                <div class="left">
                    On the frontend, we wanted to meet user expectations of what a safety-assuring app should look like. It must guarantee the user the preparation he needs to find out about his safety and upcoming danger zones. The data and images that our system collects should be available for the user and the user should be adequately protected during the ride, not only from possible interaction on the phone but also from lurking danger in the forest. Therefore, the main features are a tool for analyzing certain sections of the route, a map on which you can view commuter routes and dangerous zones, a wildlife accident guide and an active status with visual and audible warnings.
                </div>
                <br><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">2. Low until High Fideltiy</span></h5><br><br><br><br>
                <div class="left">
                    Below are our three process steps from low to high fidelity:
                </div>
                <img src="../assets/wiwaProject/webflow.png" class="picture plant6" alt=""> <br>
                <br>
                <img src="../assets/wiwaProject/midfi.png" class="picture plant7" alt=""> <br>
                <img src="../assets/wiwaProject/hifi.png" class="picture plant8" alt="">
                <br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">3. Functionality</span></h5><br><br><br><br>
                <div class="left">
                    The most important feature of the app is the warning message. Here, the user gets an audible and visual message while driving. To ensure that the user is not distracted, it is in car mode. This part of the app is designed that no interactions are assumed or a visual distraction is created.
                    Routes can be created on the main navigation page. These can be viewed on the map. A hazard potential is calculated for each of these routes, which should quickly and concisely convey to the user how dangerous it is on this route at the moment. For further information, a route can be tapped and the user receives information on danger spots, accidents that have already happened, danger times and the animal population at this spot.
                    If a wildlife accident happens, there is a step-by-step instruction and stored contact persons or the police can be reached. 
                </div><br>
            </div>
            <div class="w3-content" style="max-width:900px">
                <h5 class="w3-left headline3"><span style="font-family: 'Courier Prime'; font-size: 28px; padding-right: 16px">&#10035;</span><span style="font-family: 'Space Grotesk';">Procedure and features</span></h5><br><br><br><br>
                <div class="left">
                    Finally, we have developed a warning system in which the warning can reach the user in three ways. The warning through the traffic sign and delineator reaches the user even without the app and locally at the affected location. Drivers who have the app can also find out about upcoming danger zones before and after driving and receive a warning directly to their cell phone in the event of an emergency. This is how we focus on the safety of animals and people.
                </div><br>
                <img src="../assets/wiwaProject/render2.jpg" class="picture plant" alt=""> <br>
            </div>
        </div>
        <br><br><br><br>
        <router-link to="/datenvisualisierung"><h1 class="ani3">Next project</h1></router-link>
        <br><br><br>
    </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  name: 'wiwaView',
  components: {
      NavigationBar,
  },
  props: {},
  mounted(){
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>

.headline3{
    color: #4a8152;
    font-size: 25px;
    font-family: Courier Prime, sans-serif;
}
.ani3 {
    color: #4a8152;
}
nobr {
    color: #4a8152;
    font-weight: 400;
}
.home{
  text-align: center;
}
.width{
  display: none;
  width: 30%;
}
h3{
 font-family: Avenir, Courier Prime, sans-serif;
 color: #4a8152;
}
h4{
  margin-top: 0px;
}
h5 {
  font-size: 24px;
}
nav{
  z-index: 300;
  position: sticky;
}
.bold3{
  padding-right: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #4a8152;
}
.einleitung{
text-align: left;
  font-family: "Space Grotesk", monospace !important;
  font-size: 20px;
  font-weight: 400;
  color: #4a8152;
  font-family: Avenir, sans-serif;
  margin-bottom: 16px;
}
.headline3{
  color: #4a8152;
}
.hr3{
    border-width: 2.5px;
    border-color: #4a8152;
    border-radius: 20px 20px 20px 20px;
    margin-left: 100px;
    margin-right: 100px;
}
.row {
align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px;
}
.col-two {
  position: relative;
  flex-basis: 60%;
    display: flex;
    justify-content: right;
}
.col-one {
  flex-basis: 60%;
  position: relative;
  text-align: left;
}
.col-2 .controller{
  width: 60%;
}
.col-1 h3{
  font-family: Avenir, sans-serif;
}
.grid-content{
  float: right;
}
.overflow_wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 500px;
  right: 0px;
  overflow: hidden;
  padding: 50px 50px 20px;
  background-color: #4a8152; 
  border: 5px solid #4a8152;
}
.plant{
  padding-top: 10px;
  width: 100%;
  padding-left: 0px;
}
.Über3{
    color: #4a8152;
    font-family: "Courier Prime", sans-serif; 
}
.header{
  font-family: "Space Grotesk", monospace;
  color: black;
  font-size: 50px;
}

img{
    width: 80%;
    padding-left: 10px;
    object-fit: scale-down;
}

.text{
    color: black;
    text-align: left;
    margin-left: 50px;
}

.index{
    padding-left: 0px;
    margin-right: 50px;
}

.section{
    margin-top: 0px;
    padding: 200px 20px;
    background-color: #4a8152;
}
a{
    text-decoration: none;
}
.color{
  color: black;
}
.content{
    text-align: left;
    padding-left: 50px;
    padding-right: 0px; 
}
.left{
  margin-top: 0px;
  color: black;
  text-align: left;
  padding-top: 0px;
  font-size: 18px;
}
.ani3{
  font-size: 50px;
  color: #4a8152;
}
.ani3:hover{
  color: rgb(0, 0, 0);
}
.ani3::after{
  content: "";
  width: 0%;
  height: 3px;
  background: rgb(0, 0, 0);
  display: block;
  margin: auto;
  transition: 0.5s;
  color: #4a8152;
}
.ani3:hover:after{
  color: #4a8152;
  width: 20%;
}
.align{
  text-align: left;
  margin: 0px 80px 40px 80px;
}
.w3-container{
  margin: 0px 80px;
}

@media only screen and (max-width:900px){
  h3{
    font-size: 18px;
  }
  .header {
    font-size: 40px;
  }
}

@media only screen and (max-width:800px){
  .align{
    flex-wrap: wrap;
    position: relative;
  }
}

@media only screen and (max-width:750px){
  .ani3{
    font-size: 25px;
  }
  .ani3:hover:after{
    color: #4a8152;
    width: 30%;
  }
  .Über3{
    word-wrap: none;
  }
  .headline3{
    text-align: left;
    font-size: 25px;
  }
  .einleitung{
    font-size: 20px;
  }
  .left{
    font-size: 15px;
  }
  .bold3{
    padding-right: 400px;
  }
  .align{
    max-width: 60%;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    gap: 20px 40px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .what{
    text-align: left !important;
  }
  .w3-container{
    padding-left: 38px;
    padding-right: 50px;
  }
  .controller{
    width: 80%;
  }
  h3 {
    font-size: 16px;
  }
  .hr3 {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
  }
}
@media only screen and (max-width:550px){
  .row{
    margin: 20px 40px;
  }
  .hr3 {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }
  .header {
    font-size: 24px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    width: 80%;
  }
  .col-one {
    flex-basis: 60%;
  }
  .w3-container {
    margin: 0px 40px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .headline3 {
    font-size: 24px;
  }
}
</style>