import { createRouter, createWebHistory } from 'vue-router';
import home from '../views/Home.vue'
import work from '../views/Work.vue'
import about from '../views/AboutMe.vue'
import contact from '../views/Contact.vue'
import wiwa from '../views/Wiwa.vue'
import plantanical from '../views/Plantanical.vue'
import remotegardening from '../views/RemoteGardening.vue'
import gardenmoisture from '../views/GardenMoisture.vue'
import datenvisualisierung from '../views/Datenvisualisierung.vue'
import fatiguetracker from '../views/FatigueTracker.vue'
import zebra from '../views/Zebra.vue'
import deepflow from '../views/DeepFlow.vue'
import dunlop from '../views/Dunlop.vue'
import prisonsensoryroom from '../views/PrisonSensoryRoom.vue'
import neckar from '../views/Neckar.vue'
import malu from '../views/Malu.vue'
import protectmefromwhatiwant from '../views/ProtectMeFromWhatIWant.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/aboutme',
    name: 'about',
    component: about
  },
  {
    path: '/work',
    name: 'work',
    component: work
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/wiwa',
    name: 'wiwa',
    component: wiwa
  },
  {
    path: '/plantanical',
    name: 'plantanical',
    component: plantanical
  },
  {
    path: '/remotegardening',
    name: 'remotegardening',
    component: remotegardening
  },
  {
    path: '/datenvisualisierung',
    name: 'datenvisualisierung',
    component: datenvisualisierung
  },
  {
    path: '/fatiguetracker',
    name: 'fatiguetracker',
    component: fatiguetracker
  },
  {
    path: '/zebra',
    name: 'zebra',
    component: zebra
  },
  {
    path: '/deepflow',
    name: 'deepflow',
    component: deepflow
  },
  {
    path: '/dunlop',
    name: 'dunlop',
    component: dunlop
  },
  {
    path: '/prisonsensoryroom',
    name: 'prisonsensoryroom',
    component: prisonsensoryroom
  },
  {
    path: '/neckar',
    name: 'neckar',
    component: neckar
  },
  {
    path: '/malu',
    name: 'malu',
    component: malu
  },
  {
    path: '/gardenmoisture',
    name: 'gardenmoisture',
    component: gardenmoisture
  },
  {
    path: '/protectmefromwhatiwant',
    name: 'protectmefromwhatiwant',
    component: protectmefromwhatiwant
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

